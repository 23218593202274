export enum MetricType {
  distance = 'distance',
  averagePace = 'averagePace',
  averageSpeed = 'averageSpeed',
  caloriesBurned = 'caloriesBurned',
  elevationGained = 'elevationGained',
  averageHeartRate = 'averageHeartRate',
  maxHeartRate = 'maxHeartRate',
  maxSpeed = 'maxSpeed',
  maxPace = 'maxPace'
}

export interface CompletedActivityMetrics {
  type: MetricType;
  value: number;
  unit: string;
}
