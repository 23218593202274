import { CompletedActivityMetrics, MetricType } from '@fitup-monorepo/core/lib/model/activity/CompletedActivityMetrics';
import { DurationPipe } from '@fitup-monorepo/core/lib/pipes/duration-pipe';
import { PointConfig } from '@fitup-monorepo/components/lib/points/points.page';
import { ActivityType, CompletedActivityDetails } from '../models/activity-list';

export function isSupportedSource(source: string): boolean {
  return source !== 'fit-up' && ['garmin', 'google-fit', 'apple-health', 'polar', 'fitbit', 'suunto'].includes(source);
}

export function isFitupActivity(activityType: ActivityType): boolean {
  const types = Object.values(ActivityType).filter(t => t !== ActivityType.healthApiActivity);
  return types.includes(activityType);
}

export function getSourceName(source: string): string {
  switch (source) {
    case 'fit-up':
      return 'FIT-UP';
    case 'google-fit':
      return 'Google';
    case 'apple-health':
      return 'Apple';
    case 'polar':
      return 'Polar';
    case 'fitbit':
      return 'Fitbit';
    case 'suunto':
      return 'Suunto';
    case 'garmin':
      return 'Garmin';
    default:
      return 'FIT-UP';
  }
}

export function hasHeartRateMetric(metrics: CompletedActivityMetrics[]): boolean {
  return metrics.findIndex(v => v.type === MetricType.averageHeartRate) !== -1;
}

export function formatTimePoint(durationPipe: DurationPipe, durationInSeconds: number, time: number): string {
  return durationPipe.transform(time, durationInSeconds > 3600 ? 'hh:mm' : 'mm:ss');
}

export function getPointConfig(details: CompletedActivityDetails): PointConfig {
  return {
    pointSummary: details.fitPointEarningSummary,
    page: 'activity',
    activityDetails: {
      duration: details.durationSeconds,
      heartRate: details.metrics.find(m => MetricType.averageHeartRate === m.type)?.value,
      calories: details.metrics.find(m => MetricType.caloriesBurned === m.type)?.value,
      shareActivityConfig: {
        duration: details.durationSeconds,
        headerImage: details.headerImageUrl,
        metrics: details.metrics,
        name: details.name,
        startDate: details.startDate
      }
    }
  };
}

export function getFromDate(createdAt: string): Date {
  const date = new Date(createdAt);
  date.setTime(date.getTime() - 30 * 24 * 60 * 60 * 1000); // 30 days ago, because there might be activities fetched from Garmin or Fitbit
  return date;
}
