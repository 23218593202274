import { ActivityDetails, ActivityStats, CompletedActivitySummary } from '../models/activity-list';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { activityActions } from './activity.actions';
import { DateRange, RangeMode } from '@fitup-monorepo/components/lib/date-select/model/date-select';

export const defaultPageSize = 20;
export const overallStartDate = new Date('2024-09-01T00:00:00Z');

const fromDateToFilter: Date = new Date();
const toDateToFilter: Date = new Date();
export const defaultDateRange = {
  rangeMode: RangeMode.month,
  startDate: fromDateToFilter,
  endDate: toDateToFilter
};

export interface ActivityState extends EntityState<CompletedActivitySummary> {
  loading: boolean;
  error?: string;
  activityStats: ActivityStats;
  currentActivityStats: ActivityStats;
  previousActivityStats: ActivityStats;
  overallActivityStats: ActivityStats;
  featuredActivities: ActivityDetails[];
  lastPageSize: number;
  pageNum: number;
  overviewDateRange: DateRange;
  statisticsDateRange: DateRange;
}

export const activityAdapter: EntityAdapter<CompletedActivitySummary> = createEntityAdapter();

export const initialState: ActivityState = activityAdapter.getInitialState({
  loading: false,
  pageNum: 0,
  activityStats: undefined,
  currentActivityStats: undefined,
  previousActivityStats: undefined,
  overallActivityStats: undefined,
  featuredActivities: [],
  lastPageSize: defaultPageSize,
  overviewDateRange: undefined,
  statisticsDateRange: undefined
});

export const activityReducer = createReducer(
  initialState,
  on(
    activityActions.loadCompletedActivitySummaries,
    activityActions.loadActivityStats,
    (state): ActivityState => ({ ...state, loading: true })
  ),
  on(activityActions.loadCompletedActivitySummariesSuccess, (state, { summaries }) =>
    activityAdapter.setAll(summaries, {
      ...state,
      pageNum: 0,
      lastPageSize: summaries.length,
      loading: false
    })
  ),
  on(activityActions.loadMoreCompletedActivitySummariesSuccess, (state, { summaries }) =>
    activityAdapter.upsertMany(summaries, {
      ...state,
      pageNum: state.pageNum + 1,
      lastPageSize: summaries.length,
      loading: false
    })
  ),
  on(
    activityActions.loadFeaturedActivitiesSuccess,
    (state, { featuredActivities }): ActivityState => ({ ...state, featuredActivities })
  ),
  on(
    activityActions.loadActivityStatsSuccess,
    (state, { stats }): ActivityState => ({ ...state, activityStats: stats, loading: false })
  ),
  on(
    activityActions.loadCurrentActivityStatsSuccess,
    (state, { stats }): ActivityState => ({ ...state, currentActivityStats: stats })
  ),
  on(
    activityActions.loadPreviousActivityStatsSuccess,
    (state, { stats }): ActivityState => ({ ...state, previousActivityStats: stats })
  ),
  on(
    activityActions.loadOverallActivityStatsSuccess,
    (state, { stats }): ActivityState => ({ ...state, overallActivityStats: stats })
  ),
  on(activityActions.deleteActivitySuccess, (state): ActivityState => ({ ...state, loading: true })),
  on(
    activityActions.setOverviewDateRange,
    (state, { dateRange }): ActivityState => ({ ...state, overviewDateRange: dateRange })
  ),
  on(
    activityActions.setStatisticsDateRange,
    (state, { dateRange }): ActivityState => ({ ...state, statisticsDateRange: dateRange })
  ),
  on(activityActions.loadActivityStatsFailure, (state): ActivityState => ({ ...state, loading: false }))
);
