// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscription-box {
  border-radius: var(--fu-radius-s);
  background: var(--fu-grey-9);
  padding: var(--fu-spacing-m);
}

.content {
  font-size: var(--fu-font-size-xs);
  font-weight: var(--fu-font-medium);
}
.content .icon {
  color: var(--fu-mild-grey);
  min-width: var(--fu-icon-font-size-regular);
}
.content .label {
  color: var(--fu-grey-3);
}
.content--active .icon {
  color: var(--fu-color-primary);
}
.content--active .label {
  color: var(-fu-body-color);
}`, "",{"version":3,"sources":["webpack://./libs/subscription/src/lib/subscription/zpp-subscription/zpp-subscription.page.scss"],"names":[],"mappings":"AAAA;EACE,iCAAA;EACA,4BAAA;EACA,4BAAA;AACF;;AAEA;EACE,iCAAA;EACA,kCAAA;AACF;AACE;EACE,0BAAA;EACA,2CAAA;AACJ;AAEE;EACE,uBAAA;AAAJ;AAII;EACE,8BAAA;AAFN;AAKI;EACE,0BAAA;AAHN","sourcesContent":[".subscription-box {\n  border-radius: var(--fu-radius-s);\n  background: var(--fu-grey-9);\n  padding: var(--fu-spacing-m);\n}\n\n.content {\n  font-size: var(--fu-font-size-xs);\n  font-weight: var(--fu-font-medium);\n\n  .icon {\n    color: var(--fu-mild-grey);\n    min-width: var(--fu-icon-font-size-regular);\n  }\n\n  .label {\n    color: var(--fu-grey-3);\n  }\n\n  &--active {\n    .icon {\n      color: var(--fu-color-primary);\n    }\n\n    .label {\n      color: var(-fu-body-color);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
