export interface Milestone {
  img: string;
  value: number;
}

export const milestones: Milestone[] = [
  { img: 'assets/img/milestones-badges/activities-milestone-5.png', value: 5 },
  { img: 'assets/img/milestones-badges/activities-milestone-25.png', value: 25 },
  { img: 'assets/img/milestones-badges/activities-milestone-50.png', value: 50 },
  { img: 'assets/img/milestones-badges/activities-milestone-100.png', value: 100 },
  { img: 'assets/img/milestones-badges/activities-milestone-250.png', value: 250 },
  { img: 'assets/img/milestones-badges/activities-milestone-500.png', value: 500 },
  { img: 'assets/img/milestones-badges/activities-milestone-750.png', value: 750 },
  { img: 'assets/img/milestones-badges/activities-milestone-1000.png', value: 1000 },
  { img: 'assets/img/milestones-badges/activities-milestone-2000.png', value: 2000 }
];
