import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { WIZARD_SERVICE } from '@fitup-monorepo/core/lib/capacitor-injection-tokens';
import { WizardService } from '@fitup-monorepo/core/lib/services/wizard/wizard.service';
import { IonicModule } from '@ionic/angular';
import { SetupContainerComponent } from '@fitup-monorepo/components/lib/setup-container/setup-container.component';
import { TranslateModule } from '@ngx-translate/core';
import { CustomerSelectService } from '@fitup-monorepo/core/lib/services/customer-select/customer-select.service';
import { firstValueFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectQueryParams } from '@fitup-monorepo/core/lib/state/route/route.selector';
import { map } from 'rxjs/operators';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lib-zpp-subscribe-success',
  templateUrl: './zpp-subscribe-success.page.html',
  styleUrl: './zpp-subscribe-success.page.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, SetupContainerComponent, TranslateModule, CommonModule]
})
export class ZppSubscribeSuccessPage {
  protected readonly planId$ = this.store.select(selectQueryParams).pipe(
    map(params => {
      return params.planId;
    })
  );
  constructor(
    @Inject(WIZARD_SERVICE) private readonly wizardService: WizardService,
    private readonly customerSelectService: CustomerSelectService,
    private readonly store: Store
  ) {}

  //stripe redirects to this page with the URL
  // https://app.dev.fit-up.com/success-subscribe-zpp?hasSubscribed=true&planId=stripe-onlinekurs-ernaehrung

  protected async continueOnboarding(planId: string): Promise<void> {
    const customerMembership = await firstValueFrom(this.customerSelectService.selectMembership$());
    const membershipName = customerMembership.membership.name;
    if (membershipName === planId) {
      await this.wizardService.navigateToNextStep();
    }
  }
}
