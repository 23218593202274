import { Permission, PermissionsService } from '../permissions/permissions.service';
import { ActivatedRoute, Router } from '@angular/router';

export interface OnboardingStep {
  index: number;
  route: string;
  permission?: Permission;
  isVisible?: () => Promise<boolean>;
  isShortSignupStep?: boolean;
}

export interface WizardService {
  navigateToNextStep(): Promise<void>;

  getNextUrlForUrl(currentUrl: string): Promise<string>;

  getNextUrl(): Promise<string>;
}

export abstract class OnboardingWizardService implements WizardService {
  protected constructor(
    readonly router: Router,
    readonly activatedRoute: ActivatedRoute,
    readonly permissionsService: PermissionsService
  ) {}

  abstract getSteps(): OnboardingStep[];

  public async navigateToNextStep(): Promise<void> {
    const nextUrl = await this.getNextUrl();

    const queryParams = {};
    const currentQueryParams = this.activatedRoute.snapshot.queryParams;
    const isShortSignupWizard = currentQueryParams['shortSignup'];
    const entranceRoute = currentQueryParams['entranceRoute'];
    if (isShortSignupWizard) {
      queryParams['shortSignup'] = true;
      queryParams['entranceRoute'] = entranceRoute;
    }
    await this.router.navigate([nextUrl], { queryParams });
  }

  public async getNextUrlForUrl(currentUrl: string): Promise<string> {
    const currentStep = this.getSteps().find(o => currentUrl.includes(o.route));
    const nextSteps = this.getSteps().filter(o => o.index > currentStep.index);
    const isShortSignupWizard = this.activatedRoute.snapshot.queryParams['shortSignup'];

    for (const step of nextSteps) {
      const isVisible = !('isVisible' in step) || (await step.isVisible());
      const isNextStepVisible = isShortSignupWizard ? isVisible && step.isShortSignupStep : isVisible;

      if (isNextStepVisible && (!step.permission || this.permissionsService.hasPermission(step.permission))) {
        return step.route;
      }
    }

    return '/tabs/home';
  }

  public async getNextUrl(): Promise<string> {
    const currentRoute = this.router.url;
    return this.getNextUrlForUrl(currentRoute);
  }
}
