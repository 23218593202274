import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { environment } from '@fitup-monorepo/core/lib/environment';
import { getHeaderTheme } from '@fitup-monorepo/core/lib/util/style.util';

@Component({
  selector: 'app-sign-in-page',
  templateUrl: './sign-in.page.html',
  styleUrls: ['./sign-in.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignInPage {
  @HostBinding('class')
  public get theme(): string {
    return environment.preferredThemes.registerPage;
  }

  protected readonly getHeaderTheme = getHeaderTheme();
}
