import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MilestoneModalComponent } from './milestone-modal.component';
import { firstValueFrom } from 'rxjs';
import { Milestone, milestones } from '../../models/milestones';
import { ShareActivityConfig } from '@fitup-monorepo/components/lib/share-activity/share-activity.component';
import { Store } from '@ngrx/store';
import { selectOverallActivityStats } from '../../state/activity.selector';

@Injectable({
  providedIn: 'root'
})
export class MilestoneModalService {
  constructor(
    private readonly modalController: ModalController,
    private readonly store: Store
  ) {}

  private async openModal(milestone: Milestone, shareActivityConfig: ShareActivityConfig): Promise<void> {
    const modal = await this.modalController.create({
      id: 'milestone-modal',
      component: MilestoneModalComponent,
      componentProps: { milestone, shareActivityConfig },
      cssClass: ['fu-modal-default']
    });

    await modal.present();
    await modal.onWillDismiss();
  }

  public async showModal(shareActivityConfig: ShareActivityConfig): Promise<void> {
    const activityStats = await firstValueFrom(this.store.select(selectOverallActivityStats));
    const completedActivities = activityStats.numberOfCompletedActivities;
    const milestone = milestones.find(m => m.value === completedActivities);
    if (milestone) {
      await this.openModal(milestone, shareActivityConfig);
    }
  }
}
