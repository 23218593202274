import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ReportProblem, ReportProblemApiService } from './report-problem.api.service';
import { firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { ToastService } from '@fitup-monorepo/core/lib/services/toast/toast.service';
import { BugfenderFacade } from '@bugfender/common';
import { BUGFENDER } from '@fitup-monorepo/core/lib/capacitor-injection-tokens';

export interface ReportProblemConfig {
  title: string;
  description: string;
  placeholder: string;
}

@Component({
  selector: 'app-report-problem',
  templateUrl: './report-problem.component.html',
  styleUrls: ['./report-problem.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonicModule, ReactiveFormsModule, TranslateModule],
  standalone: true
})
export class ReportProblemComponent {
  @Input()
  public topic: string | undefined;

  @Input()
  public config: ReportProblemConfig | undefined;

  public readonly descriptionControl = new FormControl('', Validators.required);
  public hasSubmitted = false;

  constructor(
    private readonly modalController: ModalController,
    private readonly problemApiService: ReportProblemApiService,
    private readonly toastService: ToastService,
    @Inject(BUGFENDER) private readonly bugfender: BugfenderFacade,
    private readonly router: Router
  ) {}

  public async onSubmit(): Promise<void> {
    this.hasSubmitted = true;
    if (this.descriptionControl.valid) {
      await this.reportProblem();
    }
  }

  private async reportProblem(): Promise<void> {
    let bugfenderDeviceUrl: string = undefined;
    let bugfenderSessionUrl: string = undefined;

    try {
      bugfenderDeviceUrl = await this.bugfender.getDeviceURL();
    } catch (e) {}
    try {
      bugfenderSessionUrl = await this.bugfender.getSessionURL();
    } catch (e) {}

    const report: ReportProblem = {
      topic: this.topic,
      message: this.descriptionControl.value,
      params: {
        currentRoute: this.router.url,
        bugfenderDeviceUrl: bugfenderDeviceUrl,
        bugfenderSessionUrl: bugfenderSessionUrl
      }
    };

    try {
      await firstValueFrom(this.problemApiService.reportProblem(report));
      await this.toastService.showSuccess('REPORT_PROBLEM.SUCCESS_TOAST');
      await this.dismissModal();
    } catch (e) {
      await this.toastService.showError('REPORT_PROBLEM.ERROR_TOAST');
    }
  }

  protected async dismissModal(): Promise<void> {
    await this.modalController.dismiss();
  }
}
