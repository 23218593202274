import { Injectable } from '@angular/core';
import { PromoCodeService } from '@fitup-monorepo/core/lib/services/promo-code/promo-code.service';
import { firstValueFrom } from 'rxjs';
import { AuthServerProvider } from '@fitup-monorepo/core/lib/services/auth/auth-jwt.service';
import { Store } from '@ngrx/store';
import { ProfileInformation } from '@fitup-monorepo/core/lib/model/profile-information';
import { profileApiActions } from '@fitup-monorepo/core/lib/state/profile/profile.actions';
import { LocalStorageService } from 'ngx-webstorage';
import { selectCustomer } from '@fitup-monorepo/core/lib/state/profile/profile.selector';
import { filter } from 'rxjs/operators';
import { codeInvitationKey } from '@fitup-monorepo/core/lib/guards/enter-promotion-code.guard';

@Injectable({
  providedIn: 'root'
})
export class PromoCodeApplyService {
  constructor(
    private readonly promoCodeService: PromoCodeService,
    private readonly authServerProvider: AuthServerProvider,
    private readonly store: Store
  ) {}

  public async applyPromoCode(code: string): Promise<ProfileInformation> {
    const profileInformation = await firstValueFrom(this.promoCodeService.applyPromotionCode(code));
    await this.updateJWT();
    return profileInformation;
  }

  private async updateJWT(): Promise<void> {
    await this.authServerProvider.updateJWT();
    this.store.dispatch(profileApiActions.loadProfile());
  }
}

export async function showPromocodeOnboardingPage(
  store: Store,
  localStorageService: LocalStorageService,
  promoCodeApplyService: PromoCodeApplyService
): Promise<boolean> {
  const customer = await firstValueFrom(store.select(selectCustomer).pipe(filter(c => !!c)));
  if (customer.partnerId) {
    return false;
  }

  const invitationCode = localStorageService.retrieve(codeInvitationKey);
  if (invitationCode) {
    try {
      const profile = await promoCodeApplyService.applyPromoCode(invitationCode);
      return !profile.promotionCode;
    } catch (e) {
      return true;
    }
  }
  return true;
}
