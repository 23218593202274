// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --milestone-size: 180px;
}
:host .milestone-img {
  width: var(--milestone-size);
  height: var(--milestone-size);
}`, "",{"version":3,"sources":["webpack://./libs/activity/src/lib/components/milestone-modal/milestone-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;AACE;EACE,4BAAA;EACA,6BAAA;AACJ","sourcesContent":[":host {\n  --milestone-size: 180px;\n\n  .milestone-img {\n    width: var(--milestone-size);\n    height: var(--milestone-size);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
