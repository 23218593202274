// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  overflow-y: scroll;
}
.content::-webkit-scrollbar {
  display: none;
}

.checkbox {
  border-radius: var(--fu-radius-s);
  background: var(--fu-grey-9);
  padding: var(--fu-spacing-m);
}`, "",{"version":3,"sources":["webpack://./libs/onboarding/src/lib/exclusion-criteria/exclusion-criteria.page.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,aAAA;AACJ;;AAGA;EACE,iCAAA;EACA,4BAAA;EACA,4BAAA;AAAF","sourcesContent":[".content {\n  overflow-y: scroll;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n}\n\n.checkbox {\n  border-radius: var(--fu-radius-s);\n  background: var(--fu-grey-9);\n  padding: var(--fu-spacing-m);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
