import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ActivityDetails, ActivityStats, CompletedActivitySummary } from '../models/activity-list';
import { ActionError } from '@fitup-monorepo/core/lib/model/common-types';
import { DateRange, RangeMode } from '@fitup-monorepo/components/lib/date-select/model/date-select';

export const activityActions = createActionGroup({
  source: 'Activity',
  events: {
    ['Load Completed Activity Summaries']: props<{ from: Date; to: Date; pageNum?: number }>(),
    ['Load Completed Activity Summaries Success']: props<{ summaries: CompletedActivitySummary[] }>(),
    ['Load Completed Activity Summaries Failure']: props<ActionError>(),
    ['Load More Completed Activity Summaries']: props<{ from: Date; to: Date; pageNum?: number }>(),
    ['Load More Completed Activity Summaries Success']: props<{ summaries: CompletedActivitySummary[] }>(),
    ['Load Featured Activities']: emptyProps(),
    ['Load Featured Activities Success']: props<{ featuredActivities: ActivityDetails[] }>(),
    ['Load Featured Activities Failure']: props<ActionError>(),
    ['Load Activity Stats']: props<{ startDate: Date; endDate: Date; rangeMode: RangeMode }>(),
    ['Load Activity Stats Success']: props<{ stats: ActivityStats }>(),
    ['Load Current Activity Stats']: props<{ startDate: Date; endDate: Date; rangeMode: RangeMode }>(),
    ['Load Current Activity Stats Success']: props<{ stats: ActivityStats }>(),
    ['Load Previous Activity Stats']: props<{ startDate: Date; endDate: Date; rangeMode: RangeMode }>(),
    ['Load Previous Activity Stats Success']: props<{ stats: ActivityStats }>(),
    ['Load Overall Activity Stats']: props<{ startDate: Date; endDate: Date; rangeMode: RangeMode }>(),
    ['Load Overall Activity Stats Success']: props<{ stats: ActivityStats }>(),
    ['Load Activity Stats Failure']: props<ActionError>(),
    ['Delete Activity']: props<{ activityId: number }>(),
    ['Delete Activity Success']: emptyProps(),
    ['Delete Activity Failure']: props<ActionError>(),
    ['Set Overview Date Range']: props<{ dateRange: DateRange }>(),
    ['Set Statistics Date Range']: props<{ dateRange: DateRange }>()
  }
});
