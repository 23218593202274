import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from '@fitup-monorepo/components/lib/header/header.component';
import { LocalizedDatePipe } from '@fitup-monorepo/core/lib/pipes/localized-date.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule, Routes } from '@angular/router';
import { ZppSubscriptionPage } from '@fitup-monorepo/subscription/lib/subscription/zpp-subscription/zpp-subscription.page';
import { ExclusionCriteriaPage } from '@fitup-monorepo/onboarding/lib/exclusion-criteria/exclusion-criteria.page';
import { ZppSubscribeSuccessPage } from '@fitup-monorepo/subscription/lib/subscription/zpp-subscribe-success/zpp-subscribe-success.page';

const routes: Routes = [
  {
    path: 'exclusion-criteria',
    component: ExclusionCriteriaPage
  },
  {
    path: 'subscription-zpp',
    component: ZppSubscriptionPage
  },
  {
    path: 'success-subscribe-zpp',
    component: ZppSubscribeSuccessPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ZppRoutingModule {}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ZppRoutingModule,
    HeaderComponent,
    LocalizedDatePipe,
    TranslateModule,
    ZppSubscriptionPage,
    ZppSubscribeSuccessPage,
    ExclusionCriteriaPage
  ],
  declarations: []
})
export class ZppModule {}
