import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { WizardService } from '@fitup-monorepo/core/lib/services/wizard/wizard.service';
import { IonicModule } from '@ionic/angular';
import { WIZARD_SERVICE } from '@fitup-monorepo/core/lib/capacitor-injection-tokens';
import { SetupContainerComponent } from '@fitup-monorepo/components/lib/setup-container/setup-container.component';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'lib-exclusion-criteria',
  templateUrl: './exclusion-criteria.page.html',
  styleUrl: './exclusion-criteria.page.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, SetupContainerComponent, ReactiveFormsModule, TranslateModule]
})
export class ExclusionCriteriaPage {
  protected readonly checkbox = new FormControl<boolean>(false, {
    validators: [Validators.required]
  });

  constructor(@Inject(WIZARD_SERVICE) private readonly wizardService: WizardService) {}

  protected async acceptExclusionCriteria(): Promise<void> {
    await this.wizardService.navigateToNextStep();
  }
}
