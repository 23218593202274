import { Inject, Injectable } from '@angular/core';
import { AuthCredentials, AuthServerProvider } from '../auth/auth-jwt.service';
import { LocalStorageService } from 'ngx-webstorage';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { DevicePlugin } from '@capacitor/device';
import { DEVICE } from '../../capacitor-injection-tokens';
import { executeWithErrorHandling } from '../../util/rxjs-util';
import { profileApiActions } from '../../state/profile/profile.actions';
import { firebaseNotificationTokenKey } from '../../../../../settings/src/lib/pages/reminder-settings/services/push-notifications.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(
    private readonly authServerProvider: AuthServerProvider,
    private readonly localStorage: LocalStorageService,
    private readonly navController: NavController,
    private readonly store: Store,
    @Inject(DEVICE) private readonly device: DevicePlugin
  ) {}

  public async login(credentials: AuthCredentials): Promise<void> {
    await this.authServerProvider.login(credentials);
  }

  public async loginWithToken(jwt: string): Promise<void> {
    try {
      await this.authServerProvider.loginWithToken(jwt);
    } catch (reason) {
      console.log('jwt login rejected: ' + JSON.stringify(reason));
    }
  }

  public async logout(): Promise<void> {
    const firebaseNotificationToken = this.localStorage.retrieve(firebaseNotificationTokenKey);
    const authenticated = this.authServerProvider.isAuthenticated();
    const deviceId = await this.device.getId();
    if (authenticated) {
      await executeWithErrorHandling({
        observable$: this.authServerProvider.logout(deviceId.identifier, firebaseNotificationToken)
      });
    }

    this.localStorage.clear(firebaseNotificationTokenKey);
    this.localStorage.clear('health-data-status');
    this.localStorage.clear('user');
    this.localStorage.clear('user');
    this.localStorage.clear('auth_mail');
    this.localStorage.clear('ble-toggle');
    this.localStorage.clear('fitup-plan-disclaimer_status');
    this.localStorage.clear('BLE');
    this.localStorage.clear('BLE_name');

    this.store.dispatch(profileApiActions.resetProfile());

    await this.navController.navigateRoot('/');
  }
}
