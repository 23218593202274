import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs';
import BackgroundGeolocation, { Location } from '@transistorsoft/capacitor-background-geolocation';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@fitup-monorepo/core/lib/environment';
import { DEVICE } from '@fitup-monorepo/core/lib/capacitor-injection-tokens';
import { LocationLoggingService, LogRequest } from '../pages/activity-tracker/location-logging.service';
import { DevicePlugin } from '@capacitor/device';
import { Platform } from '@ionic/angular';
import { map, switchMap } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { ToastService } from '@fitup-monorepo/core/lib/services/toast/toast.service';
import { GpsLocation } from '../route';

declare type LocationServiceStateValue = 'stopped' | 'running' | 'error' | 'permission-error';

export interface LocationServiceState {
  desired: LocationServiceStateValue;
  current: LocationServiceStateValue;
}

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private state = new BehaviorSubject<LocationServiceState>({
    desired: 'stopped',
    current: 'stopped'
  });

  public state$: Observable<LocationServiceStateValue> = this.state.asObservable().pipe(map(s => s.current));

  private locationSubject = new Subject<GpsLocation>();
  public location$: Observable<GpsLocation> = this.locationSubject.asObservable();

  constructor(
    private readonly translateService: TranslateService,
    private readonly platform: Platform,
    @Inject(DEVICE) private readonly device: DevicePlugin,
    private readonly locationLoggingService: LocationLoggingService,
    private readonly toastService: ToastService
  ) {
    BackgroundGeolocation.onAuthorization(e => console.log('onAuthorization', JSON.stringify(e)));
    BackgroundGeolocation.onPowerSaveChange(e => console.log('onPowerSaveChange', JSON.stringify(e)));
    BackgroundGeolocation.onProviderChange(e => console.log('onProviderChange', JSON.stringify(e)));

    this.state.pipe(switchMap(state => fromPromise(this.handleTransition(state)))).subscribe(() => {});
  }

  public async clearRoute(): Promise<void> {
    await BackgroundGeolocation.destroyLocations();
  }

  public async getRoute(): Promise<GpsLocation[]> {
    const locations = await BackgroundGeolocation.getLocations();
    const gpsLocations = this.filterLocations(locations.map(l => this.toGpsLocation(l as Location)));

    if (gpsLocations.length >= 2) {
      const last = gpsLocations[gpsLocations.length - 1];
      const secondLast = gpsLocations[gpsLocations.length - 2];
      last.bearing = this.calculateBearing(secondLast, last);
    }

    return gpsLocations;
  }

  private filterLocations(locations: GpsLocation[]): GpsLocation[] {
    if (locations.length == 0) {
      return [];
    }

    const filtered = [];
    let time = locations[0].timeLoc;
    let lat = 0;
    let lng = 0;
    let alt = 0;
    let speed = 0;
    let currentCount = 0;

    function save() {
      const loc = {
        timeLoc: time,
        speed: speed / currentCount,
        lat: lat / currentCount,
        lng: lng / currentCount,
        alt: alt / currentCount
      };
      filtered.push(loc);
    }

    locations.forEach(l => {
      if (l.timeLoc >= time + 1) {
        save();

        currentCount = 1;
        time = l.timeLoc;
        lat = l.lat;
        lng = l.lng;
        alt = l.alt;
        speed = l.speed;
      } else {
        speed += l.speed;
        lat += l.lat;
        lng += l.lng;
        alt += l.alt;
        currentCount++;
      }
    });
    save();

    return filtered;
  }

  public setDesiredState(value: LocationServiceStateValue): void {
    if (value !== 'error' && value != 'permission-error') {
      if (this.state.value.current == 'permission-error') {
        this.patchState({ desired: value, current: 'error' }, true); //force reset
      } else {
        this.patchState({ desired: value });
      }
    } else {
      throw new Error(`Cannot set desired state to: ${value}`);
    }
  }

  private patchState(s: Partial<LocationServiceState>, force: boolean = false) {
    const newState = { ...this.state.value, ...s };
    if (JSON.stringify(newState) !== JSON.stringify(s) || force) {
      console.log('Updating state', s, newState);
      this.state.next(newState);
    }
  }

  private async handleTransition(state: LocationServiceState): Promise<void> {
    if (state.current == state.desired) return;

    const stateTransition = `${state.current} -> ${state.desired}`;

    console.log('state change', state, stateTransition);

    switch (stateTransition) {
      case 'stopped -> running':
        await this.startLocationTracking();
        break;
      case 'running -> stopped':
        await this.stopLocationTracking();
        break;
      case 'error -> stopped':
        await this.handleError();
        break;
      case 'error -> running':
        await this.handleError();
        break;
      default:
        console.error('Unknown state transition', stateTransition, JSON.stringify(state));
    }
  }

  private async startLocationTracking(): Promise<void> {
    try {
      await BackgroundGeolocation.logger.destroyLog();

      const readyState = await BackgroundGeolocation.ready({
        // Geolocation Config
        desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
        distanceFilter: 0,
        desiredOdometerAccuracy: 2,
        locationAuthorizationRequest: 'WhenInUse',
        isMoving: true,
        stopTimeout: 5, //minutes
        disableMotionActivityUpdates: false,
        disableStopDetection: true,
        heartbeatInterval: 60,

        //iOS only
        stationaryRadius: 25, //25 is the plugins minimum value
        showsBackgroundLocationIndicator: true,
        activityType: 3, //ACTIVITY_TYPE_FITNESS
        preventSuspend: true,
        pausesLocationUpdatesAutomatically: false,
        stopDetectionDelay: 0,

        //Android only
        allowIdenticalLocations: true,
        foregroundService: true,
        locationUpdateInterval: 500,
        backgroundPermissionRationale: {
          title: this.translateService.instant('LOCATION.ENABLE_LOCATION_TITLE'),
          message: this.translateService.instant('LOCATION.ENABLE_LOCATION_MESSAGE'),
          positiveAction: this.translateService.instant('LOCATION.ALLOW'),
          negativeAction: this.translateService.instant('ENABLE_LOCATION_CANCEL')
        },
        notification: {
          title: 'FIT-UP Tracking',
          text: 'FIT-UP is tracking your location'
        },
        geofenceModeHighAccuracy: true,

        // Application config
        debug: false, // <-- enable this hear sounds for background-geolocation life-cycle.
        logLevel: environment.production
          ? BackgroundGeolocation.LOG_LEVEL_INFO
          : BackgroundGeolocation.LOG_LEVEL_VERBOSE
      });

      console.log('ready state', readyState);

      try {
        const permissionState = await BackgroundGeolocation.requestPermission();
        const providerState = await BackgroundGeolocation.getProviderState();

        if (permissionState < 3) {
          console.log("We don't have permission to access location", permissionState, providerState);
          await this.toastService.showError('GPS_ERROR');
          this.patchState({ current: 'permission-error' });
          return;
        }
        console.log('provider & permission state', providerState, permissionState);
      } catch (e) {
        console.log('error requesting permission', e);
        await this.toastService.showError('GPS_ERROR');
        this.patchState({ current: 'permission-error' });
        return;
      }

      const startState = await BackgroundGeolocation.start();
      console.log('start state', startState);

      console.log('startLocationTracking', this.state);

      BackgroundGeolocation.watchPosition(
        location => {
          this.locationSubject.next(this.toGpsLocation(location));
        },
        error => {
          console.error('watchPosition error', error);
          this.patchState({ current: 'error' });
        },
        {
          interval: 500,
          desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
          persist: true,
          timeout: 10000
        }
      );

      this.patchState({ current: 'running' });
    } catch (e) {
      console.log('error initialization', e);
      this.patchState({ current: 'error' });
    }
  }

  private async stopLocationTracking(): Promise<void> {
    try {
      console.log('stop watchLocation');
      try {
        await BackgroundGeolocation.stopWatchPosition();
      } catch (e) {
        console.log('error stopWatchPosition', e);
      }

      await BackgroundGeolocation.stop();
      this.patchState({ current: 'stopped' });
    } catch (e) {
      console.log('error initialization', e);
      this.patchState({ current: 'error' });
    }

    await this.sendLogs();
  }

  private async handleError(): Promise<void> {
    console.log('Trying to recover from error', this.state.value);
    await this.stopLocationTracking();
  }

  public async sendLogs(): Promise<void> {
    try {
      if (this.platform.is('capacitor')) {
        const deviceId = await this.device.getId();
        const start = new Date();
        start.setHours(start.getHours() - 6);
        const end = new Date();
        const logs = await BackgroundGeolocation.logger.getLog({
          start: start.getTime(),
          end: end.getTime(),
          order: BackgroundGeolocation.logger.ORDER_ASC,
          limit: 20000
        });
        const request: LogRequest = {
          lines: logs.split('\n\n').map(line => line.trim())
        };
        try {
          await firstValueFrom(this.locationLoggingService.sendLogs(deviceId.identifier, request));
          console.log(`Saved BackgroundGeolocation log for device ${deviceId.identifier}`);
        } catch (e) {
          try {
            console.log('BackgroundGeolocation Log:');
            request.lines.forEach(l => console.log(l));
          } catch (e) {
            console.error('error logging BackgroundGeolocation logs', e);
          }
        }
        await BackgroundGeolocation.logger.destroyLog();
      }
    } catch (e) {
      console.error('sendLogs error', e);
    }
  }

  private toGpsLocation(location: Location): GpsLocation {
    return {
      lat: location.coords.latitude,
      lng: location.coords.longitude,
      alt: location.coords.altitude,
      bearing: location.coords.heading,
      timeLoc: new Date(location.timestamp).getTime() / 1000,
      speed: location.coords.speed
    };
  }

  private calculateBearing(l1: GpsLocation, l2: GpsLocation): number {
    const lat1Rad = l1.lat * (Math.PI / 180);
    const lat2Rad = l2.lat * (Math.PI / 180);
    const deltaLngRad = (l2.lng - l1.lng) * (Math.PI / 180);

    const y = Math.sin(deltaLngRad) * Math.cos(lat2Rad);
    const x = Math.cos(lat1Rad) * Math.sin(lat2Rad) - Math.sin(lat1Rad) * Math.cos(lat2Rad) * Math.cos(deltaLngRad);

    let bearing = Math.atan2(y, x) * (180 / Math.PI);

    bearing = (bearing + 360) % 360;

    return bearing;
  }
}
