import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SubscriptionPlanComponent } from '../../subscription-plan/subscription-plan.component';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { PriceTableConfig, StripeConfigService } from '../../subscription-plan/stripe-config.service';
import { errorLogging } from '@fitup-monorepo/core/lib/util/rxjs-util';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { SetupContainerComponent } from '@fitup-monorepo/components/lib/setup-container/setup-container.component';
import { PlatformService } from '@fitup-monorepo/core/lib/services/platform/platform.service';
import { LocalizedCurrencyPipe } from '@fitup-monorepo/core/lib/pipes/localized-currency.pipe';

@Component({
  selector: 'lib-zpp-subscription',
  templateUrl: './zpp-subscription.page.html',
  styleUrl: './zpp-subscription.page.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SubscriptionPlanComponent,
    CommonModule,
    IonicModule,
    SetupContainerComponent,
    TranslateModule,
    LocalizedCurrencyPipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ZppSubscriptionPage {
  protected language: BehaviorSubject<string> = new BehaviorSubject<string>('de');

  public readonly priceTableConfig$: Observable<PriceTableConfig> = this.stripeConfigService
    .getPriceTableConfig()
    .pipe(errorLogging());

  protected readonly labels: string[] = [
    'ZPP_ONBOARDING.LABELS.LABEL_1',
    'ZPP_ONBOARDING.LABELS.LABEL_2',
    'ZPP_ONBOARDING.LABELS.LABEL_3',
    'ZPP_ONBOARDING.LABELS.LABEL_4',
    'ZPP_ONBOARDING.LABELS.LABEL_5',
    'ZPP_ONBOARDING.LABELS.LABEL_6',
    'ZPP_ONBOARDING.LABELS.LABEL_7'
  ];

  constructor(
    private readonly stripeConfigService: StripeConfigService,
    private readonly translateService: TranslateService,
    private readonly platformService: PlatformService
  ) {}

  public ionViewWillEnter(): void {
    this.language.next(this.translateService.currentLang);
  }

  public isNative(): boolean {
    return this.platformService.isNative();
  }
}
