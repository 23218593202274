import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import {
  ActivityDetails,
  ActivityFact,
  ActivityListItem,
  ActivityStats,
  ActivityType,
  CompletedActivityDetails,
  CompletedActivitySummary,
  FbActivityTemplate,
  FbFact,
  FeaturedActivitiesResponse
} from '../models/activity-list';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FirestoreService } from '@fitup-monorepo/core/lib/services/firestore/firestore.service';
import { TranslatableTextService } from '@fitup-monorepo/core/lib/services/translatable-text/translatable-text.service';
import { ApiService } from '@fitup-monorepo/core/lib/services/api/api.service';
import { RangeMode } from '@fitup-monorepo/components/lib/date-select/model/date-select';
import { TranslateService } from '@ngx-translate/core';
import { ExternalActivity } from './external-activity';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({ providedIn: 'root' })
export class ActivityListService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly firestore: FirestoreService,
    private readonly translateText: TranslatableTextService,
    private readonly translateService: TranslateService,
    private readonly localStorageService: LocalStorageService
  ) {}

  public getActivityListItems(): Observable<ActivityListItem[]> {
    return this.#getAllActivityDetails().pipe(
      map(activities =>
        activities.map(a => {
          const { id, name, iconUrl, activityCategory, met } = a;
          return { id, name, iconUrl, activityCategory, met };
        })
      )
    );
  }

  public getActivityDetails(activityId: string): Observable<ActivityDetails> {
    return this.#getAllActivityDetails().pipe(map(l => l.find(a => a.id === activityId)));
  }

  public getFeaturedActivities(): Observable<ActivityDetails[]> {
    return this.#getAllActivityDetails().pipe(
      switchMap(activities =>
        this.httpClient.get<FeaturedActivitiesResponse>(`${ApiService.API_URL}/activities/featured`).pipe(
          map(response => activities.filter(a => response.featuredActivityIds.includes(a.id))),
          catchError(() => of(activities))
        )
      )
    );
  }

  public getCompletedActivitySummaries(
    from: Date,
    to: Date,
    pageSize?: number,
    pageNum?: number
  ): Observable<CompletedActivitySummary[]> {
    const pageNumAndSize =
      pageSize !== undefined && pageNum !== undefined ? `&pageSize=${pageSize}&pageNum=${pageNum}` : '';
    return this.httpClient
      .get<CompletedActivitySummary[]>(
        `${ApiService.API_URL}/activities/summaries?from=${from.toISOString()}&to=${to.toISOString()}${pageNumAndSize}`
      )
      .pipe(
        switchMap(summaries =>
          this.#getAllActivityDetails().pipe(
            map(activities =>
              summaries.map(summary => {
                const activityDetails = activities.find(a => a.id === summary.name.toUpperCase());

                switch (summary.activityType) {
                  case ActivityType.appTracking:
                  case ActivityType.appManual:
                    return { ...summary, iconUrl: activityDetails?.iconUrl, name: activityDetails?.name };
                  case ActivityType.digitalCoach:
                    return { ...summary, iconUrl: '/assets/icon/activities/29-other-vc.png' };
                  case ActivityType.liveGroup:
                  case ActivityType.liveSingle:
                    return { ...summary, iconUrl: '/assets/icon/activities/29-other-lc.png' };
                  case ActivityType.media:
                    return { ...summary, iconUrl: '/assets/icon/activities/29-other-mc.png' };
                  case ActivityType.healthApiActivity:
                    return {
                      ...summary,
                      iconUrl: activityDetails?.iconUrl ?? this.getExternalActivityIcon(summary.name, activities),
                      name: activityDetails?.name ?? this.getMappedExternalActivityName(summary.name)
                    };
                }

                return summary;
              })
            )
          )
        ),
        catchError(error => {
          console.log('Activity summary error', error);
          return of([]);
        })
      );
  }

  private getExternalActivityIcon(activityName: string, internalActivities: ActivityDetails[]): string {
    if (activityName === ExternalActivity.functionalStrength.externalName) {
      return internalActivities.find(a => a.id === 'FUNCTIONAL').iconUrl;
    }
    if (activityName === ExternalActivity.runningJogging.externalName) {
      return internalActivities.find(a => a.id === 'RUNNING').iconUrl;
    }
    if (activityName === ExternalActivity.strengthTraining.externalName) {
      return internalActivities.find(a => a.id === 'STRENGTH').iconUrl;
    }
    if (activityName === ExternalActivity.indoorCycling.externalName) {
      return internalActivities.find(a => a.id === 'SPINNING').iconUrl;
    }
    if (activityName === ExternalActivity.coreTraining.externalName) {
      return internalActivities.find(a => a.id === 'CORE').iconUrl;
    }
    if (ExternalActivity.biking.externalName.toLowerCase() === activityName.toLowerCase()) {
      return internalActivities.find(a => a.id === 'CYCLING').iconUrl;
    }
    if (activityName === ExternalActivity.skiingDownhill.externalName) {
      return internalActivities.find(a => a.id === 'SKIING').iconUrl;
    }
    if (
      activityName === ExternalActivity.crossCountrySkiingWs.externalName ||
      activityName === ExternalActivity.backCountrySkiing.externalName
    ) {
      return internalActivities.find(a => a.id === 'CROSSCOUNTRY').iconUrl;
    }
    if (
      activityName === ExternalActivity.skating.externalName ||
      activityName === ExternalActivity.inlineSkating.externalName
    ) {
      return internalActivities.find(a => a.id === 'INLINE_SKATING').iconUrl;
    }
    if (activityName === ExternalActivity.speedWalking.externalName) {
      return internalActivities.find(a => a.id === 'WALKING').iconUrl;
    }
    if (
      activityName === ExternalActivity.swimmingPool.externalName ||
      activityName === ExternalActivity.openWaterSwimming.externalName
    ) {
      return internalActivities.find(a => a.id === 'SWIMMING').iconUrl;
    }
    if (
      activityName === ExternalActivity.mountainBiking.externalName ||
      activityName === ExternalActivity.bikingMountain.externalName
    ) {
      return internalActivities.find(a => a.id === 'MOUNTAINBIKING').iconUrl;
    }
    if (activityName === ExternalActivity.dancingSocial.externalName) {
      return internalActivities.find(a => a.id === 'DANCING').iconUrl;
    }
    if (
      activityName === ExternalActivity.treadmillRunning.externalName ||
      activityName === ExternalActivity.runningTreadmill.externalName
    ) {
      return internalActivities.find(a => a.id === 'TREADMILL').iconUrl;
    }
    if (ExternalActivity.isOutdoor(activityName)) {
      return internalActivities.find(a => a.id === 'OTHER_OUTDOOR').iconUrl;
    }
    if (ExternalActivity.isIndoor(activityName)) {
      return internalActivities.find(a => a.id === 'OTHER_INDOOR').iconUrl;
    }
    return '/assets/icon/activities/30-other-gadgets.png';
  }

  private getMappedExternalActivityName(activityName: string): string {
    const translateKey = ExternalActivity.getTranslateKeyByName(activityName);
    return translateKey ? this.translateService.instant(translateKey) : activityName;
  }

  public getCompletedActivityDetails(activityId: number): Observable<CompletedActivityDetails> {
    return this.httpClient.get<CompletedActivityDetails>(`${ApiService.API_URL}/activities/details/${activityId}`).pipe(
      switchMap(activity =>
        this.#getAllActivityDetails().pipe(
          map(activities => ({
            ...activity,
            name: activities.find(a => a.id === activity.name.toUpperCase())?.name ?? activity.name,
            headerImageUrl:
              activities.find(ac => ac.id === activity.name.toUpperCase())?.headerImageUrl ?? activity.headerImageUrl
          }))
        )
      )
    );
  }

  public getActivityStats(startDate: Date, endDate: Date, rangeMode: RangeMode): Observable<ActivityStats> {
    return this.httpClient.get<ActivityStats>(
      `${
        ApiService.API_URL
      }/activities/activity-stats?from=${startDate.toISOString()}&to=${endDate.toISOString()}&rangeMode=${rangeMode}`
    );
  }

  #getAllActivityDetails(): Observable<ActivityDetails[]> {
    return this.firestore.observeCollection<FbActivityTemplate>('activity-template').pipe(
      map(arr => arr.map(details => this.#toActivityDetails(details)).sort((a, b) => a.name.localeCompare(b.name))),
      map(arr => {
        if (!arr || arr.length === 0) {
          console.log('No activity details found in firestore, trying local storage');
          arr = this.localStorageService.retrieve('activityDetails');
        } else {
          console.log('Activity details found in firestore, storing in local storage');
          this.localStorageService.store('activityDetails', arr);
        }
        return arr;
      }),
      catchError(e => {
        const storedDetails = this.localStorageService.retrieve('activityDetails');
        if (storedDetails) {
          return of(storedDetails);
        } else {
          return throwError(() => e);
        }
      })
    );
  }

  #toActivityDetails(details: FbActivityTemplate, activityId?: string): ActivityDetails {
    const { name, headerCaption, description, facts, ...rest } = details;
    return {
      id: details.id ? details.id : activityId,
      name: this.translateText.translate(name),
      headerCaption: this.translateText.translate(headerCaption),
      description: this.translateText.translate(description),
      facts: facts.map(fact => this.#toActivityFact(fact)),
      ...rest
    };
  }

  #toActivityFact(fact: FbFact): ActivityFact {
    const { description, ...rest } = fact;
    return {
      description: this.translateText.translate(description),
      ...rest
    };
  }

  public uploadGpxFile(gpx: string): Observable<CompletedActivityDetails> {
    return this.httpClient.post<CompletedActivityDetails>(`${ApiService.API_URL}/activities/gpx`, gpx);
  }
}
