import { createFeatureSelector, createSelector } from '@ngrx/store';
import { activityAdapter, ActivityState } from './activity.reducer';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import { ActivityDetails, ActivityStats, CompletedActivitySummary } from '../models/activity-list';
import { sortByCreatedAt } from '@fitup-monorepo/core/lib/util/array-util';
import { DateRange } from '@fitup-monorepo/components/lib/date-select/model/date-select';

export const selectActivityFeature = createFeatureSelector<ActivityState>('activity');
export const selectActivityState = createSelector(selectActivityFeature, state => state);
export const { selectEntities: selectActivityEntities } = activityAdapter.getSelectors(selectActivityState);

export const selectCompletedActivitySummaries: MemoizedSelector<ActivityState, CompletedActivitySummary[]> =
  createSelector(selectActivityState, state => sortByCreatedAt(Object.values(state.entities), 'desc', 'startDate'));

export const selectActivityStats: MemoizedSelector<ActivityState, ActivityStats> = createSelector(
  selectActivityState,
  state => state.activityStats
);

export const selectCurrentActivityStats: MemoizedSelector<ActivityState, ActivityStats> = createSelector(
  selectActivityState,
  state => state.currentActivityStats
);

export const selectPreviousActivityStats: MemoizedSelector<ActivityState, ActivityStats> = createSelector(
  selectActivityState,
  state => state.previousActivityStats
);

export const selectOverallActivityStats: MemoizedSelector<ActivityState, ActivityStats> = createSelector(
  selectActivityState,
  state => state.overallActivityStats
);

export const selectFeaturedActivities: MemoizedSelector<ActivityState, ActivityDetails[]> = createSelector(
  selectActivityState,
  state => state.featuredActivities
);

export const selectLastPageSize: MemoizedSelector<ActivityState, number> = createSelector(
  selectActivityState,
  state => state.lastPageSize
);

export const selectPageNumber: MemoizedSelector<ActivityState, number> = createSelector(
  selectActivityState,
  state => state.pageNum
);

export const selectOverviewDateRange: MemoizedSelector<ActivityState, DateRange> = createSelector(
  selectActivityState,
  state => state.overviewDateRange
);

export const selectStatisticsDateRange: MemoizedSelector<ActivityState, DateRange> = createSelector(
  selectActivityState,
  state => state.statisticsDateRange
);

export const selectLoading: MemoizedSelector<ActivityStats, boolean> = createSelector(
  selectActivityState,
  state => state.loading
);
