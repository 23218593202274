import { createReducer, on } from '@ngrx/store';
import { dailyTasksActions } from './daily-tasks.actions';
import { DailyTasksOverview } from '../model/daily-task';

export type DailyTasksState = Omit<DailyTasksOverview, 'day'> & {
  loading: boolean;
};

export const initialState: DailyTasksState = {
  id: undefined,
  loading: false,
  tasks: [],
  completed: false,
  progress: 0
};

export const dailyTasksReducer = createReducer(
  initialState,
  on(dailyTasksActions.loadDailyTasks, (state): DailyTasksState => ({ ...state, loading: true })),
  on(
    dailyTasksActions.loadDailyTasksSuccess,
    (state, { completed, progress, tasks }): DailyTasksState => ({
      ...state,
      completed,
      tasks,
      progress,
      loading: false
    })
  )
);
