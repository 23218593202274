// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
}
:host.transparent {
  background-color: unset;
}
:host.transparent ion-toolbar {
  --background: transparent;
}
:host ion-title {
  --min-height: 74px;
  height: 74px;
}

ion-toolbar {
  --min-height: 74px;
  padding-right: 0 !important;
  padding-left: 0 !important;
  --padding-start: var(--fu-spacing-m);
  --padding-end: var(--fu-spacing-m);
  --padding-top: 14px;
  --padding-bottom: 14px;
  --background: var(--fu-body-bg);
  --color: var(--fu-body-color);
}
ion-toolbar .toolbar-container {
  min-height: 64px;
}

.account-icon {
  object-fit: cover;
  border-radius: 50%;
  height: 46px;
  width: 46px;
}
.account-icon--active {
  border-color: var(--fu-blue-moon);
}

.header-icons, ion-title {
  --color: var(--fu-body-color);
  color: var(--fu-body-color);
}

.close-icon {
  font-size: 20px;
}

.header-title {
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./libs/components/src/lib/header/header.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;AACF;AACE;EACE,uBAAA;AACJ;AACI;EACE,yBAAA;AACN;AAGE;EACE,kBAAA;EACA,YAAA;AADJ;;AAKA;EACE,kBAAA;EAMA,2BAAA;EACA,0BAAA;EACA,oCAAA;EACA,kCAAA;EACA,mBAAA;EACA,sBAAA;EACA,+BAAA;EACA,6BAAA;AAPF;AAJE;EACE,gBAAA;AAMJ;;AAOA;EACE,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;AAJF;AAME;EACE,iCAAA;AAJJ;;AAQA;EACE,6BAAA;EACA,2BAAA;AALF;;AAQA;EACE,eAAA;AALF;;AAQA;EACE,eAAA;AALF","sourcesContent":[":host {\n  display: block;\n  width: 100%;\n\n  &.transparent {\n    background-color: unset;\n\n    ion-toolbar {\n      --background: transparent;\n    }\n  }\n\n  ion-title {\n    --min-height: 74px;\n    height: 74px;\n  }\n}\n\nion-toolbar {\n  --min-height: 74px;\n\n  .toolbar-container {\n    min-height: 64px;\n  }\n\n  padding-right: 0 !important;\n  padding-left: 0 !important;\n  --padding-start: var(--fu-spacing-m);\n  --padding-end: var(--fu-spacing-m);\n  --padding-top: 14px;\n  --padding-bottom: 14px;\n  --background: var(--fu-body-bg);\n  --color: var(--fu-body-color);\n}\n\n.account-icon {\n  object-fit: cover;\n  border-radius: 50%;\n  height: 46px;\n  width: 46px;\n\n  &--active {\n    border-color: var(--fu-blue-moon);\n  }\n}\n\n.header-icons, ion-title {\n  --color: var(--fu-body-color);\n  color: var(--fu-body-color);\n}\n\n.close-icon {\n  font-size: 20px;\n}\n\n.header-title {\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
