import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FirestoreService } from '@fitup-monorepo/core/lib/services/firestore/firestore.service';
import { Store } from '@ngrx/store';
import { selectCustomer } from '@fitup-monorepo/core/lib/state/profile/profile.selector';
import { DailyTasksOverview } from './model/daily-task';

@Injectable({
  providedIn: 'root'
})
export class DailyTasksService {
  constructor(
    private readonly firestoreService: FirestoreService,
    private readonly store: Store
  ) {}

  public getOverview(day: string): Observable<DailyTasksOverview> {
    return this.store.select(selectCustomer).pipe(
      filter(customer => !!customer),
      switchMap(customer =>
        this.firestoreService
          .findAndObserveDocuments<DailyTasksOverview>(`customer/${customer.uuid}/dailyTasks`, [
            { field: 'day', operator: '==', value: day }
          ])
          .pipe(
            filter(c => c.length > 0),
            switchMap(c => {
              return this.firestoreService.observeDocument<DailyTasksOverview>(
                `customer/${customer.uuid}/dailyTasks/${c[0].id}`
              );
            })
          )
      )
    );
  }
}
