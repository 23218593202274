import { Injectable } from '@angular/core';
import { ActivityMetricsField, DrawImageConfig, ShareActivityConfig } from './share-activity.component';
import { DistancePipe } from '@fitup-monorepo/core/lib/pipes/distance.pipe';
import { DurationPipe } from '@fitup-monorepo/core/lib/pipes/duration-pipe';
import { LocalizedDatePipe } from '@fitup-monorepo/core/lib/pipes/localized-date.pipe';
import { CompletedActivityMetrics, MetricType } from '@fitup-monorepo/core/lib/model/activity/CompletedActivityMetrics';
import { TranslateService } from '@ngx-translate/core';

export interface ActivityShareImageParameters {
  width: number;
  height: number;
  devicePixelRatio: number;
  config: ShareActivityConfig;
}

@Injectable({
  providedIn: 'root'
})
export class ActivityShareImageService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly distancePipe: DistancePipe,
    private readonly durationPipe: DurationPipe,
    private readonly localizedDatePipe: LocalizedDatePipe
  ) {}

  public async drawImage(canvas: HTMLCanvasElement, parameters: ActivityShareImageParameters): Promise<void> {
    const context = this.setupCanvas(canvas, parameters.devicePixelRatio, parameters.width, parameters.height);

    await this.drawCanvasImage(
      context,
      parameters.config.headerImage,
      {
        dx: 0,
        dy: 0,
        dw: parameters.width,
        dh: parameters.height
      },
      parameters.width,
      parameters.height
    );

    const linearGradient = context.createLinearGradient(0, 0, 0, parameters.height);
    linearGradient.addColorStop(0, 'rgba(0, 0, 0, 0.40)');
    linearGradient.addColorStop(0.01, 'rgba(44, 45, 55, 0.20)');
    linearGradient.addColorStop(1, '#2C2D37');
    context.fillStyle = linearGradient;
    context.fillRect(0, 0, parameters.width, parameters.height);

    //fit-up logo
    await this.drawCanvasImage(context, '/assets/img/fit-up-logo-sm.png', {
      dx: parameters.width / 2,
      dy: 25,
      dw: 24,
      dh: 24
    });

    //heart rate
    if (this.getCustomMetric(parameters.config.metrics, MetricType.averageHeartRate)) {
      await this.drawCanvasImage(context, '/assets/img/avg-heart-rate.png', { dx: 20, dy: 25, dw: 20, dh: 20 });

      this.drawCanvasText(
        context,
        '14px Plus Jakarta Sans',
        'start',
        '#ffffff',
        this.getCustomMetric(parameters.config.metrics, MetricType.averageHeartRate).value + ' bpm',
        45,
        40
      );
    }

    //calories
    if (this.getCustomMetric(parameters.config.metrics, MetricType.caloriesBurned)) {
      const calories = this.getCustomMetric(parameters.config.metrics, MetricType.caloriesBurned).value;

      await this.drawCanvasImage(context, '/assets/img/fire.png', {
        dx: parameters.width - calories.toString().length * 8 - 75,
        dy: 25,
        dw: 20,
        dh: 20
      });

      this.drawCanvasText(
        context,
        '14px Plus Jakarta Sans',
        'end',
        '#ffffff',
        calories + ' kCal',
        parameters.width - 20,
        40
      );
    }

    //Activity type
    this.drawCanvasText(
      context,
      'bold 24px Plus Jakarta Sans',
      'center',
      '#ffffff',
      parameters.config.name,
      parameters.width / 2,
      parameters.height / 2 + 50
    );

    //Date and time
    this.drawCanvasText(
      context,
      '14px Plus Jakarta Sans',
      'center',
      '#D1DAE3',
      this.localizedDatePipe.transform(parameters.config.startDate, 'longDateTime'),
      parameters.width / 2,
      parameters.height / 2 + 80
    );

    const metrics = this.getActivityMetrics(parameters.config);

    if (metrics.length) {
      //Divider
      context.beginPath();
      context.moveTo(20, parameters.height / 2 + 100);
      context.lineTo(parameters.width - 20, parameters.height / 2 + 100);
      context.lineWidth = 1;
      context.strokeStyle = 'rgba(232, 236, 240, 0.30)';
      context.stroke();

      const restOfWidth =
        metrics.length === 2
          ? parameters.width - this.getMetricsStartPosition(metrics.length, parameters.width)
          : parameters.width - this.getMetricsStartPosition(metrics.length, parameters.width) * 2;

      //Metrics
      metrics.map((m, i) => {
        // metric value
        if (m.type === MetricType.distance) {
          this.drawCanvasText(
            context,
            '20px Gobold',
            'center',
            '#ffffff',
            this.distancePipe.transform(m.value),
            this.getMetricsStartPosition(metrics.length, parameters.width) + (i * restOfWidth) / 2,
            parameters.height / 2 + 140
          );
        } else if (m.type === MetricType.averagePace) {
          this.drawCanvasText(
            context,
            '20px Gobold',
            'center',
            '#ffffff',
            this.durationPipe.transform(m.value * 60, 'm:ss'),
            this.getMetricsStartPosition(metrics.length, parameters.width) + (i * restOfWidth) / 2,
            parameters.height / 2 + 140
          );
        } else if (m.type === 'duration') {
          this.drawCanvasText(
            context,
            '20px Gobold',
            'center',
            '#ffffff',
            this.durationPipe.transform(m.value, 'hh:mm:ss'),
            this.getMetricsStartPosition(metrics.length, parameters.width) + (i * restOfWidth) / 2,
            parameters.height / 2 + 140
          );
        } else {
          this.drawCanvasText(
            context,
            '20px Gobold',
            'center',
            '#ffffff',
            m.value.toString(),
            this.getMetricsStartPosition(metrics.length, parameters.width) + (i * restOfWidth) / 2,
            parameters.height / 2 + 140
          );
        }

        //metric title
        this.drawCanvasText(
          context,
          '12px Plus Jakarta Sans',
          'center',
          '#d1dae3',
          this.translateService.instant(m.title) + (m.unit ? ` (${m.unit})` : ''),
          this.getMetricsStartPosition(metrics.length, parameters.width) + (i * restOfWidth) / 2,
          parameters.height / 2 + 160
        );
      });
    }
  }

  private async drawCanvasImage(
    context: CanvasRenderingContext2D,
    src: string,
    drawImage: DrawImageConfig,
    canvasWidth?: number,
    canvasHeight?: number
  ): Promise<void> {
    return new Promise(resolve => {
      const backgroundImage = new Image();
      backgroundImage.crossOrigin = 'anonymous';

      const cover = this.fit(false);

      backgroundImage.onload = (): void => {
        const { dx, dy, dw, dh } =
          canvasWidth && canvasHeight
            ? cover(canvasWidth, canvasHeight, backgroundImage.width, backgroundImage.height)
            : { dx: drawImage.dx, dy: drawImage.dy, dw: drawImage.dw, dh: drawImage.dh };

        context.drawImage(backgroundImage, dx, dy, dw, dh);

        resolve();
      };
      backgroundImage.src = src;
    });
  }

  private fit(contains: boolean) {
    return (
      parentWidth: number,
      parentHeight: number,
      childWidth: number,
      childHeight: number,
      scale = 1,
      offsetX = 0.5,
      offsetY = 0.5
    ): DrawImageConfig => {
      const childRatio = childWidth / childHeight;
      const parentRatio = parentWidth / parentHeight;
      let width = parentWidth * scale;
      let height = parentHeight * scale;

      if (contains ? childRatio > parentRatio : childRatio < parentRatio) {
        height = width / childRatio;
      } else {
        width = height * childRatio;
      }

      return {
        dw: width,
        dh: height,
        dx: (parentWidth - width) * offsetX,
        dy: (parentHeight - height) * offsetY
      };
    };
  }

  private drawCanvasText(
    context: CanvasRenderingContext2D,
    font: string,
    textAlign: CanvasTextAlign,
    color: string,
    text: string,
    x: number,
    y: number
  ): void {
    context.font = font;
    context.textAlign = textAlign;
    context.fillStyle = color;
    context.fillText(text, x, y);
  }

  private setupCanvas(
    canvas: HTMLCanvasElement,
    devicePixelRatio: number,
    width: number,
    height: number
  ): CanvasRenderingContext2D {
    const dpr = devicePixelRatio || 1;
    const rect = canvas.getBoundingClientRect();
    canvas.width = (rect.width > 0 ? rect.width : width) * dpr;
    canvas.height = (rect.height > 0 ? rect.height : height) * dpr;
    const context = canvas.getContext('2d');
    context.scale(dpr, dpr);
    return context;
  }

  private getMetricsStartPosition(length: number, width: number): number {
    if (length === 1) {
      return width / 2;
    } else if (length === 2) {
      return width / 3;
    }
    return 65;
  }

  private getActivityMetrics(config: ShareActivityConfig): ActivityMetricsField[] {
    const durationField: ActivityMetricsField = {
      value: config.duration,
      title: 'ACTIVITY.ACTIVITY_TRACKING.TRACKER_METRICS.DURATION',
      type: 'duration'
    };

    const metrics: ActivityMetricsField[] = config.metrics
      .map(metric => {
        return metric.value > 0
          ? metric.type === MetricType.distance
            ? {
                value: metric?.value,
                unit: metric?.value > DistancePipe.kilometer ? 'km' : metric.unit,
                title: 'ACTIVITY.ACTIVITY_TRACKING.TRACKER_METRICS.DISTANCE_COVERED',
                type: metric.type
              }
            : metric.type === MetricType.averagePace || metric.type === MetricType.averageSpeed
            ? {
                value: metric?.value,
                unit: metric.unit,
                title:
                  metric.type === MetricType.averagePace
                    ? 'ACTIVITY.ACTIVITY_TRACKING.TRACKER_METRICS.AVG_PACE'
                    : 'ACTIVITY.ACTIVITY_TRACKING.TRACKER_METRICS.AVG_SPEED',
                type: metric.type
              }
            : undefined
          : undefined;
      })
      .filter(m => !!m);

    if (durationField.value) {
      metrics.splice(1, 0, durationField);
    }

    return metrics;
  }

  private getCustomMetric(metrics: CompletedActivityMetrics[], metricType: MetricType): CompletedActivityMetrics {
    return metrics.find(m => m.type === metricType);
  }
}
