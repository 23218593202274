import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PointConfig, PointsPage } from './points.page';

@Injectable({
  providedIn: 'root'
})
export class PointsService {
  constructor(private readonly modalController: ModalController) {}

  public async showFitPointModal(pointConfig: PointConfig): Promise<void> {
    if (pointConfig.pointSummary && pointConfig.pointSummary.earnedPoints > 0) {
      const modal = await this.modalController.create({
        id: 'point-modal',
        component: PointsPage,
        componentProps: {
          pointConfig
        },
        cssClass: ['fu-modal-default', 'fu-modal-default--ml']
      });
      await modal.present();
      await modal.onWillDismiss();
    }
  }
}
