import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';


@Component({
  selector: 'app-detail-page',
  templateUrl: './detail-page.component.html',
  styleUrls: ['./detail-page.component.scss'],
  standalone: true,
  imports: [IonicModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailPageComponent {
  @Input()
  public image: string;

  @Input()
  public imageHeight: number = 510;

  @Input()
  public imageSectionTheme: string;

  @Input()
  public buttonText: string;

  @Input()
  public buttonIconName: string;

  @Input()
  public showButtonLoading = false;

  @Input()
  public buttonDisabled = false;

  @Input()
  public showButton = true;

  @Output()
  public buttonClick = new EventEmitter<void>();

  public getBackgroundImage(image: string): string {
    const overlay = '0deg, rgba(74, 77, 89, 0.5), rgba(74, 77, 89, 0.5)';
    return `background: linear-gradient(${overlay}), url('${image}') 50% 0 / cover no-repeat`;
  }
}
