import { ChangeDetectionStrategy, Component, DestroyRef, Inject, Input, OnInit, signal } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { DetailPageComponent } from '../detail-page/detail-page.component';
import { IonicModule, ModalController } from '@ionic/angular';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { CompletedActivityMetrics, MetricType } from '@fitup-monorepo/core/lib/model/activity/CompletedActivityMetrics';
import { DistancePipe } from '@fitup-monorepo/core/lib/pipes/distance.pipe';
import { DurationPipe } from '@fitup-monorepo/core/lib/pipes/duration-pipe';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SharePlugin } from '@capacitor/share';
import { Directory, FilesystemPlugin } from '@capacitor/filesystem';
import { BehaviorSubject, firstValueFrom, pairwise, startWith, Subject, switchMap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, tap } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { ActivityShareImageParameters, ActivityShareImageService } from './activity-share-image.service';
import { ImagePickerDirective } from '../image-picker-modal/image-picker.directive';
import { FILESYSTEM, SHARE } from '@fitup-monorepo/core/lib/capacitor-injection-tokens';

export interface ActivityMetricsField {
  value: number;
  unit?: string;
  title: string;
  type: MetricType | 'duration';
}

export interface ShareActivityConfig {
  headerImage: string;
  name: string;
  metrics: CompletedActivityMetrics[];
  startDate: Date;
  duration: number;
}

export enum ActivityFeeling {
  proud = 'proud',
  happy = 'happy',
  motivated = 'motivated',
  tired = 'tired',
  exhausted = 'exhausted',
  strong = 'strong',
  relaxed = 'relaxed'
}

export enum EmojiUnicode {
  proud = '😊',
  happy = '😀',
  motivated = '😎',
  tired = '😮‍💨',
  exhausted = '🥵',
  strong = '💪',
  relaxed = '😌'
}

export interface DrawImageConfig {
  dx: number;
  dy: number;
  dw: number;
  dh: number;
}

@Component({
  selector: 'app-share-activity',
  templateUrl: './share-activity.component.html',
  styleUrls: ['./share-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    HeaderComponent,
    DetailPageComponent,
    IonicModule,
    ReactiveFormsModule,
    ImagePickerDirective,
    DistancePipe,
    DurationPipe,
    TranslateModule
  ]
})
export class ShareActivityComponent implements OnInit {
  @Input()
  public activityDetail: ShareActivityConfig;

  protected readonly isActivitySharing = signal(false);
  private takeScreenshot$ = new Subject<void>();

  private fileUrl$ = new BehaviorSubject<string>(null);

  protected readonly imageHeight: number = 393;
  protected readonly defaultPhoto = 'assets/img/share-activity-default-photo.png';
  protected readonly imageOverlay = '180deg, rgba(0, 0, 0, 0.40) 0%, rgba(44, 45, 55, 0.20) 0.01%, #2C2D37 100%';
  protected readonly metricType = MetricType;
  protected readonly imageSectionId: string = 'share-activity-image-section';
  protected readonly activityFeelings: { emoji: string; title: string; value: ActivityFeeling }[] = [
    { emoji: EmojiUnicode.proud, title: 'Proud', value: ActivityFeeling.proud },
    { emoji: EmojiUnicode.happy, title: 'Happy', value: ActivityFeeling.happy },
    { emoji: EmojiUnicode.motivated, title: 'Motivated', value: ActivityFeeling.motivated },
    { emoji: EmojiUnicode.tired, title: 'Tired', value: ActivityFeeling.tired },
    { emoji: EmojiUnicode.exhausted, title: 'Exhausted', value: ActivityFeeling.exhausted },
    { emoji: EmojiUnicode.strong, title: 'Strong', value: ActivityFeeling.strong },
    { emoji: EmojiUnicode.relaxed, title: 'Relaxed', value: ActivityFeeling.relaxed }
  ];

  protected readonly formGroup = this.formBuilder.group({
    feeling: new FormControl(ActivityFeeling.proud),
    photo: new FormControl(undefined)
  });

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(SHARE) private readonly share: SharePlugin,
    @Inject(FILESYSTEM) private readonly filesystem: FilesystemPlugin,
    private readonly modalController: ModalController,
    private readonly formBuilder: FormBuilder,
    private readonly destroyRef: DestroyRef,
    private readonly translateService: TranslateService,
    private readonly activityShareImageService: ActivityShareImageService
  ) {}

  public ngOnInit(): void {
    this.formGroup.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), startWith(this.formGroup.value), pairwise())
      .subscribe(([prev, next]) => {
        if (prev.photo !== next.photo) {
          this.takeScreenshot$.next();
        }
      });

    this.takeScreenshot$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.fileUrl$.next(null)),
        switchMap(() => {
          return fromPromise(this.drawCanvas(this.activityDetail));
        }),
        switchMap(canvas => {
          return fromPromise(this.savePhoto(canvas));
        })
      )
      .subscribe(url => this.fileUrl$.next(url));
  }

  public ionViewWillEnter(): void {
    this.takeScreenshot$.next();
  }

  public async drawCanvas(config: ShareActivityConfig): Promise<string> {
    const canvas = this.document.getElementById('share-activity-canvas') as HTMLCanvasElement;
    const innerWidth = this.document?.defaultView.innerWidth;
    const width = canvas.clientWidth > 0 ? canvas.clientWidth : innerWidth;
    const height = canvas.clientHeight > 0 ? canvas.clientHeight : this.imageHeight;
    const devicePixelRatio = window.devicePixelRatio;

    const parameters: ActivityShareImageParameters = {
      width,
      height,
      config: {
        ...config,
        headerImage: this.formGroup.value.photo ?? config.headerImage ?? 'assets/img/share-activity-default-img.jpg'
      },
      devicePixelRatio
    };

    await this.activityShareImageService.drawImage(canvas, parameters);

    return canvas.toDataURL('image/jpeg');
  }

  protected async savePhoto(photo: string): Promise<string> {
    const fileName = new Date() + '.jpeg';
    const savedFile = await this.filesystem.writeFile({
      path: fileName,
      data: photo,
      directory: Directory.Cache
    });
    return savedFile.uri;
  }

  protected async sharePhoto(): Promise<void> {
    this.isActivitySharing.set(true);

    const fileUrl = await firstValueFrom(this.fileUrl$.pipe(filter(f => !!f)));
    const feeling = this.formGroup.value.feeling;
    try {
      await this.share.share({
        url: fileUrl,
        text:
          this.translateService.instant('SHARE_ACTIVITY.SHARE_FILE_TEXT') +
          ' ' +
          this.translateService.instant('SHARE_ACTIVITY.' + feeling.toUpperCase()) +
          ' ' +
          this.getEmojiByValue(feeling) +
          '!' +
          '\n'
      });
      await this.closeModal();
      this.isActivitySharing.set(false);
    } catch (error) {
      this.isActivitySharing.set(false);
    }
  }

  protected async uploadPhoto(photo: string): Promise<void> {
    this.formGroup.patchValue({ photo });
  }

  protected deletePhoto(): void {
    this.formGroup.patchValue({ photo: undefined });
  }

  protected getEmojiByValue(feeling: ActivityFeeling): string {
    const emoji = Object.keys(EmojiUnicode).find(e => e === feeling);
    return EmojiUnicode[emoji];
  }

  protected getCanvasStyle(): string {
    return `
    width: 100%;
    height: ${this.imageHeight}px;`;
  }

  public static async openModal(modalController: ModalController, activityDetail?: ShareActivityConfig): Promise<void> {
    const modal = await modalController.create({
      component: ShareActivityComponent,
      id: 'share-activity-modal',
      componentProps: { activityDetail: activityDetail },
      cssClass: ['fu-modal-default', 'fu-modal-default--medium', 'fu-modal--centered-button']
    });
    await modal.present();
  }

  protected async closeModal(): Promise<void> {
    await this.modalController.dismiss(null, null, 'share-activity-modal');
  }
}
