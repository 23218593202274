import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { activityActions } from './activity.actions';
import { of, switchMap } from 'rxjs';
import { ActivityListService } from '../services/activity-list.service';
import { catchError, map, tap } from 'rxjs/operators';
import { ActivityMutationService } from '../services/activity-mutation.service';
import { defaultPageSize } from './activity.reducer';
import { Store } from '@ngrx/store';
import { selectPageNumber } from './activity.selector';
import { concatLatestFrom } from '@ngrx/operators';
import { NavController } from '@ionic/angular';

@Injectable()
export class ActivityEffects {
  public readonly loadCompletedActivitySummaries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activityActions.loadCompletedActivitySummaries, activityActions.loadMoreCompletedActivitySummaries),
      concatLatestFrom(() => this.store.select(selectPageNumber)),
      switchMap(([{ ...action }, pageNum]) =>
        this.activityListService
          .getCompletedActivitySummaries(action.from, action.to, defaultPageSize, action.pageNum ?? pageNum)
          .pipe(
            map(summaries =>
              action.type === activityActions.loadMoreCompletedActivitySummaries.type
                ? activityActions.loadMoreCompletedActivitySummariesSuccess({ summaries })
                : activityActions.loadCompletedActivitySummariesSuccess({ summaries })
            ),
            catchError(error => of(activityActions.loadCompletedActivitySummariesFailure({ error })))
          )
      )
    )
  );

  public readonly loadFeaturedActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activityActions.loadFeaturedActivities),
      switchMap(() =>
        this.activityListService.getFeaturedActivities().pipe(
          map(featuredActivities => activityActions.loadFeaturedActivitiesSuccess({ featuredActivities })),
          catchError(error => of(activityActions.loadFeaturedActivitiesFailure({ error })))
        )
      )
    )
  );

  public readonly loadActivityStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activityActions.loadActivityStats),
      switchMap(({ startDate, endDate, rangeMode }) =>
        this.activityListService.getActivityStats(startDate, endDate, rangeMode).pipe(
          map(stats => activityActions.loadActivityStatsSuccess({ stats })),
          catchError(error => of(activityActions.loadActivityStatsFailure({ error })))
        )
      )
    )
  );

  public readonly loadCurrentActivityStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activityActions.loadCurrentActivityStats),
      switchMap(({ startDate, endDate, rangeMode }) =>
        this.activityListService.getActivityStats(startDate, endDate, rangeMode).pipe(
          map(stats => activityActions.loadCurrentActivityStatsSuccess({ stats })),
          catchError(error => of(activityActions.loadActivityStatsFailure({ error })))
        )
      )
    )
  );

  public readonly loadPreviousActivityStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activityActions.loadPreviousActivityStats),
      switchMap(({ startDate, endDate, rangeMode }) =>
        this.activityListService.getActivityStats(startDate, endDate, rangeMode).pipe(
          map(stats => activityActions.loadPreviousActivityStatsSuccess({ stats })),
          catchError(error => of(activityActions.loadActivityStatsFailure({ error })))
        )
      )
    )
  );

  public readonly loadOverallActivityStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activityActions.loadOverallActivityStats),
      switchMap(({ startDate, endDate, rangeMode }) =>
        this.activityListService.getActivityStats(startDate, endDate, rangeMode).pipe(
          map(stats => activityActions.loadOverallActivityStatsSuccess({ stats })),
          catchError(error => of(activityActions.loadActivityStatsFailure({ error })))
        )
      )
    )
  );

  public readonly deleteActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activityActions.deleteActivity),
      switchMap(({ activityId }) =>
        this.activityMutationService.deleteActivity(activityId).pipe(
          map(() => activityActions.deleteActivitySuccess()),
          tap(() => {
            this.navController.navigateRoot('/tabs/activity');
          }),
          catchError(error => of(activityActions.deleteActivityFailure({ error })))
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly activityListService: ActivityListService,
    private readonly activityMutationService: ActivityMutationService,
    private readonly store: Store,
    private readonly navController: NavController
  ) {}
}
