import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SignInPage } from './sign-in.page';
import { Oauth2ResolveComponent } from './oauth2-resolve.component';
import { InputElementComponent } from '@fitup-monorepo/components/lib/input-element/input-element.component';
import { SetupContainerComponent } from '@fitup-monorepo/components/lib/setup-container/setup-container.component';
import { HeaderComponent } from '@fitup-monorepo/components/lib/header/header.component';
import { EditorComponent } from '@fitup-monorepo/components/lib/editor/editor.component';
import { SignInComponent } from './sign-in/sign-in.component';

const routes: Routes = [
  {
    path: '',
    component: SignInPage
  },
  {
    path: 'oauth2',
    component: Oauth2ResolveComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslateModule,
    ReactiveFormsModule,
    InputElementComponent,
    SetupContainerComponent,
    HeaderComponent,
    EditorComponent,
    SignInComponent
  ],
  declarations: [SignInPage, Oauth2ResolveComponent]
})
export class SignInEmailModule {}
