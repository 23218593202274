import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AccountPage } from './account.page';
import { NgChartsModule } from 'ng2-charts';
import { ChartComponent } from '@fitup-monorepo/components/lib/chart/chart.component';
import { ProfileComponent } from '@fitup-monorepo/components/lib/profile/profile.component';
import { ProfilePictureComponent } from '@fitup-monorepo/components/lib/profile-picture/profile-picture.component';
import { HeaderComponent } from '@fitup-monorepo/components/lib/header/header.component';
import { DateSelectComponent } from '@fitup-monorepo/components/lib/date-select/date-select.component';
import { SegmentComponent } from '@fitup-monorepo/components/lib/segment/segment.component';
import { FuBarChartComponent } from '@fitup-monorepo/components/lib/fu-chart/fu-bar-chart/fu-bar-chart.component';
import { AdminOnlyDirective } from '@fitup-monorepo/core/lib/directives/admin-only.directive';
import { CtaButtonComponent } from '@fitup-monorepo/components/lib/cta-button/cta-button.component';
import { LiveClassCardItemComponent } from '@fitup-monorepo/components/lib/live-class-card-item/live-class-card-item.component';
import { BannerComponent } from '@fitup-monorepo/components/lib/banner/banner.component';
import { TranslatableTextPipe } from '@fitup-monorepo/core/lib/pipes/translatable-text.pipe';
import { accountPageGuard } from './account.page.guard';
import { EffectsModule } from '@ngrx/effects';
import { AccountPageEffects } from './account.page.effects';
import { StartGuideOverviewComponent } from '@fitup-monorepo/start-guide/lib/start-guide-overview/start-guide-overview.component';
import { SharedModule } from '@fitup-monorepo/components/lib/shared.module';
import { SubscriptionComponent } from '@fitup-monorepo/subscription/lib/subscription/subscription-component/subscription.component';

const routes: Routes = [
  {
    path: '',
    component: AccountPage,
    canActivate: [accountPageGuard]
  }
];

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    TranslateModule,
    NgChartsModule,
    SharedModule,
    ReactiveFormsModule,
    ProfilePictureComponent,
    HeaderComponent,
    DateSelectComponent,
    SegmentComponent,
    FuBarChartComponent,
    AdminOnlyDirective,
    CtaButtonComponent,
    SubscriptionComponent,
    LiveClassCardItemComponent,
    BannerComponent,
    TranslatableTextPipe,
    EffectsModule.forFeature([AccountPageEffects]),
    ProfileComponent,
    StartGuideOverviewComponent
  ],
  declarations: [AccountPage, ChartComponent]
})
export class AccountPageModule {}
