import { environment } from '../environment';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppCheckerService {
  constructor() {}

  public isAppFitup(): boolean {
    return 'fitup' === environment.appName;
  }

  public isEmailDomainSignupOnly(): boolean {
    return this.isAppOrf() || this.isImcKremsHost();
  }

  public isAppOrf(): boolean {
    return environment.urlScheme === 'com.catapult.orf';
  }

  public isImcKremsHost(): boolean {
    return window.location.host === 'imc.fit-up.com' || window.location.host === 'imc.dev.fit-up.com';
  }

  public showOAuthLogin(): boolean {
    return !this.isAppOrf() && !this.isImcKremsHost();
  }

  public isZppHost(): boolean {
    return window.location.host === 'praevention.fit-up.com' || window.location.host === 'praevention.dev.fit-up.com';
  }
}
