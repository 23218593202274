import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '@fitup-monorepo/core/lib/environment';
import { routerReducer, RouterState } from '@ngrx/router-store';

import { badgeReducer, BadgeState } from '@fitup-monorepo/badges/lib/state/badge.reducer';
import {
  pinboardFilterReducer,
  PinboardFilterState
} from '@fitup-monorepo/notifications/lib/state/pinboard-filter/pinboard-filter.reducer';
import {
  notificationsReducer,
  NotificationsState
} from '@fitup-monorepo/core/lib/state/notifications/notifications.reducer';
import {
  waterIntakeReducer,
  WaterIntakeState
} from '@fitup-monorepo/home-dashboard/lib/components/water-tracking/state/water-intake.reducer';
import {
  dailyTasksReducer,
  DailyTasksState
} from '@fitup-monorepo/home-dashboard/lib/components/daily-tasks-overview/state/daily-tasks.reducer';
import { challengeReducer, ChallengeState } from '@fitup-monorepo/challenge/lib/state/challenge.reducer';
import { profileReducer, ProfileState } from '@fitup-monorepo/core/lib/state/profile/profile.reducer';
import {
  miniChallengeReducer,
  MiniChallengeState
} from '@fitup-monorepo/home-dashboard/lib/components/mini-challenges/state/mini-challenges.reducer';
import { groupReducer, GroupState } from '@fitup-monorepo/challenge/lib/state/group/group.reducer';
import { activityReducer, ActivityState } from '@fitup-monorepo/activity/lib/state/activity.reducer';

export interface State {
  profile: ProfileState;
  router: RouterState;
  challenge: ChallengeState;
  badge: BadgeState;
  pinboardFilter: PinboardFilterState;
  dailyTasks: DailyTasksState;
  miniChallenges: MiniChallengeState;
  notifications: NotificationsState;
  waterIntake: WaterIntakeState;
  group: GroupState;
  activity: ActivityState;
}

export const reducers: ActionReducerMap<State> = {
  profile: profileReducer,
  router: routerReducer,
  challenge: challengeReducer,
  badge: badgeReducer,
  pinboardFilter: pinboardFilterReducer,
  dailyTasks: dailyTasksReducer,
  miniChallenges: miniChallengeReducer,
  notifications: notificationsReducer,
  waterIntake: waterIntakeReducer,
  group: groupReducer,
  activity: activityReducer
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
