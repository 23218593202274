import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { welcomePageGuard } from '@fitup-monorepo/onboarding/lib/welcome/welcome-page.guard';
import { pinboardGuard } from '@fitup-monorepo/notifications/lib/pinboard.guard';
import { DummyComponent } from '@fitup-monorepo/components/lib/dummy/dummy.component';
import { loggedOutOnlyGuard } from '@fitup-monorepo/core/lib/guards/logged-out-only.guard';
import { joinChallengeGuard } from '@fitup-monorepo/core/lib/guards/join-challenge.guard';
import { enterPromotionCodeGuard, enterVoucherGuard } from '@fitup-monorepo/core/lib/guards/enter-promotion-code.guard';
import { authGuard } from '@fitup-monorepo/core/lib/guards/auth.guard';
import { challengesFeatureGuard } from '@fitup-monorepo/challenge/lib/challenges.guard';
import { challengeDetailGuard } from '@fitup-monorepo/challenge/lib/pages/challenge-detail/challenge-detail.guard';
import { MealSearchPage } from '@fitup-monorepo/nutrition-planner/lib/meal-search/meal-search.page';
import { AddCustomMealPage } from '@fitup-monorepo/nutrition-planner/lib/add-custom-meal/add-custom-meal.page';
import { CreateCustomMealPage } from '@fitup-monorepo/nutrition-planner/lib/create-custom-meal/create-custom-meal.page';
import { RecipeOverviewPage } from '@fitup-monorepo/nutrition-planner/lib/recipe-overview/recipe-overview.page';
import { PlanProgressPage } from '@fitup-monorepo/nutrition-planner/lib/plan-progress/plan-progress.page';
import { ConfirmMealSwapPage } from '@fitup-monorepo/nutrition-planner/lib/confirm-meal-swap/confirm-meal-swap.page';
import { joinGroupGuard } from '@fitup-monorepo/core/lib/guards/join-group.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./modules/welcome-wrapper.module').then(m => m.WelcomeWrapperModule),
    canActivate: [welcomePageGuard]
  },
  {
    path: 'welcome',
    loadChildren: () => import('./modules/welcome-wrapper.module').then(m => m.WelcomeWrapperModule),
    canActivate: [loggedOutOnlyGuard]
  },
  {
    path: 'join/:challengeId',
    canActivate: [joinChallengeGuard],
    component: DummyComponent
  },
  {
    path: 'join-group/:groupId',
    canActivate: [joinGroupGuard],
    component: DummyComponent
  },
  {
    path: 'company/:promotionCode',
    canActivate: [enterPromotionCodeGuard],
    component: DummyComponent
  },
  {
    path: 'voucher/:voucher',
    canActivate: [enterVoucherGuard],
    component: DummyComponent
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [authGuard]
  },
  {
    path: 'partner/:publicPinboardId',
    loadChildren: () => import('./modules/pinboard-public-wrapper.module').then(m => m.PinboardPublicWrapperModule),
    canActivate: [pinboardGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/sign-in-wrapper.module').then(m => m.SignInWrapperModule),
    canActivate: [loggedOutOnlyGuard]
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./modules/register-with-email-wrapper.module').then(m => m.RegisterWithEmailWrapperModule),
    canActivate: [loggedOutOnlyGuard]
  },
  {
    path: 'email-verification',
    loadChildren: () =>
      import('./modules/email-verification-wrapper.module').then(m => m.EmailVerificationWrapperModule),
    canActivate: [loggedOutOnlyGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./modules/forgot-password-wrapper.module').then(m => m.ForgotPasswordWrapperModule),
    canActivate: [loggedOutOnlyGuard]
  },
  { path: 'accessdenied', redirectTo: '', pathMatch: 'full' },
  {
    path: 'account',
    loadChildren: () => import('./modules/account-wrapper.module').then(m => m.AccountWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'virtual-coach',
    loadChildren: () => import('./modules/virtual-coach-wrapper.module').then(m => m.VirtualCoachWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'activity',
    loadChildren: () => import('./modules/activity-page-wrapper.module').then(m => m.ActivityPageWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'email-confirmation',
    loadChildren: () =>
      import('./modules/email-confirmation-wrapper.module').then(m => m.EmailConfirmationWrapperModule),
    canActivate: [loggedOutOnlyGuard]
  },
  {
    path: 'reset',
    loadChildren: () => import('./modules/password-reset-wrapper.module').then(m => m.PasswordResetWrapperModule),
    canActivate: [loggedOutOnlyGuard]
  },
  {
    path: 'password-confirmation',
    loadChildren: () =>
      import('./modules/password-confirmation-wrapper.module').then(m => m.PasswordConfirmationWrapperModule),
    canActivate: [loggedOutOnlyGuard]
  },
  {
    path: 'team-selection',
    loadChildren: () => import('./modules/team-selection-wrapper.module').then(m => m.TeamSelectionWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'congratulations',
    loadChildren: () =>
      import('./modules/team-congratulations-wrapper.module').then(m => m.TeamCongratulationsWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./modules/register-wrapper.module').then(m => m.RegisterWrapperModule)
  },
  {
    path: 'start-onboard',
    loadChildren: () => import('./modules/start-onboard-wrapper.module').then(m => m.StartOnboardWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'promo-code',
    loadChildren: () => import('./modules/promo-code-wrapper.module').then(m => m.PromoCodeWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'edit-account-details',
    loadChildren: () => import('./modules/edit-profile-wrapper.module').then(m => m.EditProfileWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'edit-profile-picture',
    loadChildren: () =>
      import('./modules/edit-profile-picture-wrapper.module').then(m => m.EditProfilePictureWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'gender-and-birthdate',
    loadChildren: () =>
      import('./modules/gender-and-birthdate-wrapper.module').then(m => m.GenderAndBirthdateWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'fit-point-details',
    loadChildren: () => import('./modules/fit-point-details-wrapper.module').then(m => m.FitPointDetailsWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'meal-swap',
    loadChildren: () => import('./modules/MealSwapWrapperModule').then(m => m.MealSwapWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'meal-swap/confirm',
    component: ConfirmMealSwapPage,
    canActivate: [authGuard]
  },
  {
    path: 'plan-progress',
    component: PlanProgressPage,
    canActivate: [authGuard]
  },
  {
    path: 'plan-detail',
    loadChildren: () => import('./modules/PlanDetailPageWrapperModule').then(m => m.PlanDetailPageWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'plan-started',
    loadChildren: () => import('./modules/PlanStartedWrapperModule').then(m => m.PlanStartedWrapperModule),
    canActivate: [authGuard]
  },

  {
    path: 'meal-detail',
    loadChildren: () => import('./modules/MealDetailPageWrapperModule').then(m => m.MealDetailPageWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'admin-dashboard',
    loadChildren: () => import('./pages/admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardPageModule),
    canActivate: [authGuard]
  },
  {
    path: 'food-search',
    loadComponent: () => MealSearchPage,
    canActivate: [authGuard]
  },
  {
    path: 'add-food',
    loadComponent: () => AddCustomMealPage,
    canActivate: [authGuard]
  },
  {
    path: 'challenges/:challengeId',
    loadChildren: () => import('./modules/challenge-detail-wrapper.module').then(m => m.ChallengeDetailWrapperModule),
    canActivate: [authGuard, challengeDetailGuard, challengesFeatureGuard]
  },
  {
    path: 'measurements',
    loadChildren: () => import('./modules/measurements-wrapper.module').then(m => m.MeasurementsWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'create-custom-meal',
    component: CreateCustomMealPage,
    canActivate: [authGuard]
  },
  {
    path: 'trainer-dashboard',
    loadChildren: () =>
      import('./pages/trainer-dashboard/trainer-dashboard.module').then(m => m.TrainerDashboardPageModule),
    canActivate: [authGuard]
  },
  {
    path: 'success-onboard',
    loadChildren: () => import('./modules/success-onboard-wrapper.module').then(m => m.SuccessOnboardWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'live-classes',
    loadChildren: () => import('./pages/live-classes/live-classes.module').then(m => m.LiveClassesPageModule),
    canActivate: [authGuard]
  },
  {
    path: 'subscription-free-trial',
    loadChildren: () =>
      import('./modules/SubscriptionFreeTrialPageWrapperModule').then(m => m.SubscriptionFreeTrialPageWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'notifications',
    loadChildren: () => import('./modules/notifications-wrapper.module').then(m => m.NotificationsWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'fitness-level',
    loadChildren: () => import('./modules/fitness-level-wrapper.module').then(m => m.FitnessLevelWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'fitness-goals',
    loadChildren: () => import('./modules/fitness-goals-wrapper.module').then(m => m.FitnessGoalsWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'interests',
    loadChildren: () => import('./modules/interests-wrapper.module').then(m => m.InterestsWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'account-details-editor',
    loadChildren: () =>
      import('./modules/account-details-editor-wrapper.module').then(m => m.AccountDetailsEditorWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'reminder',
    loadChildren: () =>
      import('./modules/reminder-settings-onboard-wrapper.module').then(m => m.ReminderSettingsOnboardWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'full-ranking-list/:challengeId',
    loadChildren: () => import('./modules/full-ranking-list-wrapper.module').then(m => m.FullRankingListWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'pinboard-public-filter',
    loadChildren: () =>
      import('./modules/pinboard-public-filter-wrapper.module').then(m => m.PinboardPublicFilterWrapperModule)
  },
  {
    path: 'promo-code-error',
    loadChildren: () => import('./modules/code-error-wrapper.module').then(m => m.CodeErrorWrapperModule)
  },
  {
    path: 'voucher-error',
    loadChildren: () => import('./modules/code-error-wrapper.module').then(m => m.CodeErrorWrapperModule)
  },
  {
    path: 'challenges',
    loadChildren: () => import('./modules/challenge-wrapper.module').then(m => m.ChallengeWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'user-options-onboarding',
    loadChildren: () =>
      import('./modules/user-options-onboarding-wrapper.module').then(m => m.UserOptionsOnboardingWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings-wrapper.module').then(m => m.SettingsWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'finalize-onboard',
    loadChildren: () => import('./modules/finalize-onboard-wrapper.module').then(m => m.FinalizeOnboardWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'start-guide',
    loadChildren: () => import('./modules/start-guide-wrapper.module').then(m => m.StartGuideWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'badges',
    loadChildren: () => import('./modules/badges-wrapper.module').then(m => m.BadgesWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'recipe-overview',
    loadComponent: () => RecipeOverviewPage,
    canActivate: [authGuard]
  },
  {
    path: 'explore-groups',
    loadChildren: () => import('./modules/explore-groups-wrapper.module').then(m => m.ExploreGroupsWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'create-group',
    loadChildren: () => import('./modules/create-group-wrapper.module').then(m => m.CreateGroupWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'groups/:groupId',
    loadChildren: () => import('./modules/group-detail-wrapper.module').then(m => m.GroupDetailWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'partner-full-ranking',
    loadChildren: () =>
      import('./modules/partner-full-ranking-wrapper.module').then(m => m.PartnerFullRankingWrapperModule),
    canActivate: [authGuard]
  },
  {
    path: 'plan-questionnaire/:date',
    loadChildren: () =>
      import('./modules/plan-questionnaire-wrapper.module').then(m => m.PlanQuestionnaireWrapperModule)
  },
  {
    path: 'plan-questionnaire-result',
    loadChildren: () =>
      import('./modules/plan-questionnaire-success-wrapper.module').then(m => m.PlanQuestionnaireSuccessWrapperModule)
  }
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  preloadingStrategy: PreloadAllModules
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
