import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuChartComponent, FuChartType } from '../fu-chart.component';
import { BehaviorSubject } from 'rxjs';
import { ChartConfiguration } from 'chart.js';

export interface FuBarDataSet<T> {
  color: string;
  borderColor?: string;
  borderWidth?: number;
  values: T[];
  label: string;
  barThickness?: number;
  labelUnit?: string;
}

export interface FuBarChartData<T> {
  xAxisLabels: string[];
  datasets: FuBarDataSet<T>[];
}

@Component({
  selector: 'app-fu-bar-chart',
  templateUrl: './fu-bar-chart.component.html',
  styleUrls: ['./fu-bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FuChartComponent]
})
export class FuBarChartComponent implements OnChanges {
  @Input()
  public data: FuBarChartData<number> | undefined;
  public readonly data$ = new BehaviorSubject<ChartConfiguration<'bar', number, string>['data']>({
    labels: [],
    datasets: []
  });
  public readonly chartType = FuChartType.bar;
  public readonly options$ = new BehaviorSubject<ChartConfiguration<'bar', number, string>['options']>(
    this.#getBarChartConfiguration()
  );

  public ngOnChanges(): void {
    this.options$.next(this.#getBarChartConfiguration());
    this.data$.next(this.#getBarChartDataConfig());
  }

  #getBarChartDataConfig(): ChartConfiguration<any, number, string>['data'] {
    return {
      labels: this.data ? this.data.xAxisLabels : [],
      datasets: this.data?.datasets.map(dataset => ({
        label: dataset?.label,
        data: dataset?.values,
        backgroundColor: dataset?.color,
        borderColor: dataset?.borderColor,
        barThickness: dataset?.barThickness,
        borderWidth: dataset?.borderWidth
      }))
    };
  }

  #getBarChartConfiguration(): ChartConfiguration<'bar', number, string>['options'] {
    const labelToolTip = (context): string => {
      let labelYValue = '';
      this.data.datasets.map(dataset => {
        if (context.parsed.y !== null && dataset.labelUnit) {
          labelYValue = context.parsed.y + ' ' + dataset.labelUnit;
        } else if (context.parsed.y !== null) {
          labelYValue = context.parsed.y;
        } else {
          labelYValue = '---';
        }
      });
      return labelYValue;
    };

    return {
      animation: false,
      hover: { mode: null },
      aspectRatio: 2.5,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            display: false
          },
          ticks: {
            color: '#AFB4C6',
            font: {
              family: 'Plus Jakarta Sans',
              size: 14,
              weight: 600
            }
          }
        },
        x: {
          ticks: {
            color: '#AFB4C6',
            font: {
              family: 'Plus Jakarta Sans',
              size: 14,
              weight: 600
            }
          }
        }
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          yAlign: 'bottom',
          displayColors: false,
          boxPadding: 10,
          padding: 10,
          titleColor: '#AFB4C6',
          titleMarginBottom: 4,
          titleFont: {
            size: 12,
            weight: 600,
            family: 'Plus Jakarta Sans'
          },
          caretPadding: 10,
          caretSize: 8,
          cornerRadius: 6,
          intersect: false,
          bodyFont: {
            size: 14,
            weight: 600,
            family: 'Plus Jakarta Sans'
          },
          callbacks: {
            label: labelToolTip,
            labelTextColor: (): string => '#fff'
          }
        }
      }
    };
  }
}
