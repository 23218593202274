import { FirestoreEntity } from '@fitup-monorepo/core/lib/services/firestore/firestore.model';
import { TranslatableString } from '@fitup-monorepo/core/lib/services/translatable-text/translatable-string';
import { TrackDetails } from './track-details';
import { FitPointEarningSummary } from '@fitup-monorepo/core/lib/model/fit-point-earning-summary';
import { CompletedActivityMetrics } from '@fitup-monorepo/core/lib/model/activity/CompletedActivityMetrics';

export enum ActivityCategory {
  indoor = 'indoor',
  outdoor = 'outdoor'
}

export interface ActivityListItem {
  id: string;
  name: string;
  iconUrl: string;
  activityCategory: ActivityCategory;
  met: number;
}

export interface ActivityFact {
  id: string;
  icon: string;
  value: number;
  unit: string;
  description: string;
}

export interface FeaturedActivitiesResponse {
  featuredActivityIds: string[];
}

export interface ActivityDetails extends ActivityListItem {
  id: string;
  name: string;
  activityCategory: ActivityCategory;
  iconUrl: string;
  requiresLocation: boolean;
  headerImageUrl: string;
  headerCaption: string;
  description: string;
  facts: ActivityFact[];
  met: number;
}

export interface ActivityStats {
  numberOfCompletedActivities: number;
  totalActivityCalories: number;
  totalActivityTimeSeconds: number;
  activityDataAvailableFrom: Date;
  timeSeries: TimeSeries[];
}

export interface TimeSeries {
  type: string;
  values: TimeSeriesValue[];
}

export interface TimeSeriesValue {
  pointInTime: string;
  aggregatedBy: 'year' | 'month' | 'day';
  value: number;
}

export interface CompletedActivitySummary {
  id: number;
  startDate: string;
  name: string;
  activityType: ActivityType;
  source: string;
  durationSeconds: number;
  distance?: number;
  calories?: number;
  averageHeartRate?: number;
  iconUrl: string; //not returned by backend, inferred from type and name
}

export enum ActivityType {
  liveSingle = 'LIVE_SINGLE',
  liveGroup = 'LIVE_GROUP',
  media = 'MEDIA',
  digitalCoach = 'DIGITAL_COACH',
  appTracking = 'APP_TRACKING',
  appManual = 'APP_MANUAL',
  steps = 'STEPS',
  flights = 'FLIGHTS',
  healthApiActivity = 'HEALTH_API_ACTIVITY'
}

export enum TerrainType {
  road = 'ROAD',
  track = 'TRACK',
  trail = 'TRAIL',
  soft = 'SOFT',
  indoor = 'INDOOR',
  other = 'OTHER'
}

export enum Effort {
  light = 'LIGHT',
  easy = 'EASY',
  moderate = 'MODERATE',
  high = 'HIGH',
  extreme = 'EXTREME'
}

export interface CompletedActivityDetails {
  id: number;
  startDate: Date;
  name: string;
  activityType: ActivityType;
  source: string;
  durationSeconds: number;
  metrics: CompletedActivityMetrics[];
  trackDetails: TrackDetails;
  terrainType?: TerrainType;
  effort?: Effort;
  fitPointEarningSummary?: FitPointEarningSummary;
  headerImageUrl?: string;
}

/**
 * Firebase entities, these must be in sync with the firebase schema
 */
export interface FbActivityTemplate extends FirestoreEntity {
  id: string;
  name: TranslatableString;
  description: TranslatableString;
  headerCaption: TranslatableString;
  activityCategory: ActivityCategory;
  iconUrl: string;
  headerImageUrl: string;
  requiresLocation: boolean;
  facts: FbFact[];
  met: number;
}

export interface FbFact extends FirestoreEntity {
  id: string;
  description: TranslatableString;
  icon: string;
  unit: string;
  value: number;
}
