export interface ExternalActivityEntry {
  externalName: string;
  translateKey: string;
}

export class ExternalActivity {
  public static readonly bikingStationary: ExternalActivityEntry = {
    externalName: 'BIKING.STATIONARY',
    translateKey: 'EXTERNAL_ACTIVITIES.BIKING_STATIONARY',
  };
  public static readonly breathWork: ExternalActivityEntry = {
    externalName: 'BREATHWORK',
    translateKey: 'EXTERNAL_ACTIVITIES.BREATHWORK',
  };
  public static readonly calisthenics: ExternalActivityEntry = {
    externalName: 'Calisthenics',
    translateKey: 'EXTERNAL_ACTIVITIES.CALISTHENICS',
  };
  public static readonly coolDown: ExternalActivityEntry = {
    externalName: 'Cooldown',
    translateKey: 'EXTERNAL_ACTIVITIES.COOLDOWN',
  };
  public static readonly coreTraining: ExternalActivityEntry = {
    externalName: 'Core_training',
    translateKey: 'EXTERNAL_ACTIVITIES.CORE_TRAINING',
  };
  public static readonly elliptical: ExternalActivityEntry = {
    externalName: 'Elliptical',
    translateKey: 'EXTERNAL_ACTIVITIES.ELLIPTICAL',
  };
  public static readonly functionalStrength: ExternalActivityEntry = {
    externalName: 'Functional_strength',
    translateKey: 'EXTERNAL_ACTIVITIES.FUNCTIONAL_STRENGTH',
  };
  public static readonly gymnastics: ExternalActivityEntry = {
    externalName: 'Gymnastics',
    translateKey: 'EXTERNAL_ACTIVITIES.GYMNASTICS',
  };
  public static readonly indoorCardio: ExternalActivityEntry = {
    externalName: 'INDOOR_CARDIO',
    translateKey: 'INDOOR_CARDIO',
  };
  public static readonly indoorCycling: ExternalActivityEntry = {
    externalName: 'INDOOR_CYCLING',
    translateKey: 'EXTERNAL_ACTIVITIES.INDOOR_CYCLING',
  };
  public static readonly intervalTraining: ExternalActivityEntry = {
    externalName: 'Interval_training',
    translateKey: 'EXTERNAL_ACTIVITIES.INTERVAL_TRAINING',
  };
  public static readonly intervalTrainingHighIntensity: ExternalActivityEntry = {
    externalName: 'INTERVAL_TRAINING.HIGH_INTENSITY',
    translateKey: 'EXTERNAL_ACTIVITIES.HIGH_INTENSITY_INTERVAL',
  };
  public static readonly lapSwimming: ExternalActivityEntry = {
    externalName: 'LAP_SWIMMING',
    translateKey: 'EXTERNAL_ACTIVITIES.LAP_SWIMMING',
  };
  public static readonly football: ExternalActivityEntry = {
    externalName: 'Football',
    translateKey: 'EXTERNAL_ACTIVITIES.FOOTBALL',
  };
  public static readonly footballSoccer: ExternalActivityEntry = {
    externalName: 'FOOTBALL.SOCCER',
    translateKey: 'EXTERNAL_ACTIVITIES.FOOTBALL',
  };
  public static readonly mixedMetabolicCardio: ExternalActivityEntry = {
    externalName: 'Mixed_metabolic_cardio',
    translateKey: 'EXTERNAL_ACTIVITIES.MIXED_METABOLIC_CARDIO',
  };
  public static readonly rowing: ExternalActivityEntry = {
    externalName: 'ROWING',
    translateKey: 'EXTERNAL_ACTIVITIES.ROWING',
  };
  public static readonly runningJogging: ExternalActivityEntry = {
    externalName: 'RUNNING.JOGGING',
    translateKey: 'EXTERNAL_ACTIVITIES.RUNNING_JOGGING',
  };
  public static readonly snowboarding: ExternalActivityEntry = {
    externalName: 'Snowboarding',
    translateKey: 'EXTERNAL_ACTIVITIES.SNOWBOARDING',
  };
  public static readonly stairClimbing: ExternalActivityEntry = {
    externalName: 'Stair_climbing',
    translateKey: 'EXTERNAL_ACTIVITIES.STAIR_CLIMBING',
  };
  public static readonly steps: ExternalActivityEntry = {
    externalName: 'STEPS',
    translateKey: 'EXTERNAL_ACTIVITIES.STEPS',
  };
  public static readonly strengthTraining: ExternalActivityEntry = {
    externalName: 'Strength_training',
    translateKey: 'EXTERNAL_ACTIVITIES.STRENGTH_TRAINING',
  };
  public static readonly squash: ExternalActivityEntry = {
    externalName: 'SQUASH',
    translateKey: 'EXTERNAL_ACTIVITIES.SQUASH',
  };
  public static readonly tennis: ExternalActivityEntry = {
    externalName: 'Tennis',
    translateKey: 'EXTERNAL_ACTIVITIES.TENNIS',
  };
  public static readonly treadmillRunning: ExternalActivityEntry = {
    externalName: 'TREADMILL_RUNNING',
    translateKey: 'EXTERNAL_ACTIVITIES.TREADMILL_RUNNING',
  };

  public static readonly biking: ExternalActivityEntry = {
    externalName: 'Biking',
    translateKey: 'EXTERNAL_ACTIVITIES.BIKING',
  };

  public static readonly other: ExternalActivityEntry = {
    externalName: 'Other',
    translateKey: 'EXTERNAL_ACTIVITIES.OTHER',
  };

  public static readonly resortSkiingSnowBoardingWs: ExternalActivityEntry = {
    externalName: 'RESORT_SKIING_SNOWBOARDING_WS',
    translateKey: 'EXTERNAL_ACTIVITIES.RESORT_SKIING_SNOWBOARDING_WS',
  };

  public static readonly circuitTraining: ExternalActivityEntry = {
    externalName: 'Circuit_training',
    translateKey: 'EXTERNAL_ACTIVITIES.CIRCUIT_TRAINING',
  };

  public static readonly skiingDownhill: ExternalActivityEntry = {
    externalName: 'SKIING.DOWNHILL',
    translateKey: 'EXTERNAL_ACTIVITIES.SKIING_DOWNHILL',
  };

  public static readonly snowSports: ExternalActivityEntry = {
    externalName: 'SNOW_SPORTS',
    translateKey: 'EXTERNAL_ACTIVITIES.SNOW_SPORTS',
  };

  public static readonly crossCountrySkiingWs: ExternalActivityEntry = {
    externalName: 'CROSS_COUNTRY_SKIING_WS',
    translateKey: 'EXTERNAL_ACTIVITIES.CROSS_COUNTRY_SKIING_WS',
  };

  public static readonly guidedBreathing: ExternalActivityEntry = {
    externalName: 'GUIDED_BREATHING',
    translateKey: 'EXTERNAL_ACTIVITIES.GUIDED_BREATHING',
  };

  public static readonly skating: ExternalActivityEntry = {
    externalName: 'Skating',
    translateKey: 'EXTERNAL_ACTIVITIES.SKATING',
  };

  public static readonly flexibility: ExternalActivityEntry = {
    externalName: 'Flexibility',
    translateKey: 'EXTERNAL_ACTIVITIES.FLEXIBILITY',
  };

  public static readonly indoorRowing: ExternalActivityEntry = {
    externalName: 'INDOOR_ROWING',
    translateKey: 'EXTERNAL_ACTIVITIES.INDOOR_ROWING',
  };

  public static readonly badminton: ExternalActivityEntry = {
    externalName: 'Badminton',
    translateKey: 'EXTERNAL_ACTIVITIES.BADMINTON',
  };

  public static readonly weightLifting: ExternalActivityEntry = {
    externalName: 'Weightlifting',
    translateKey: 'EXTERNAL_ACTIVITIES.WEIGHT_LIFTING',
  };

  public static readonly horsebackRiding: ExternalActivityEntry = {
    externalName: 'Horseback_riding',
    translateKey: 'EXTERNAL_ACTIVITIES.HORSEBACK_RIDING',
  };

  public static readonly aerobics: ExternalActivityEntry = {
    externalName: 'Aerobics',
    translateKey: 'EXTERNAL_ACTIVITIES.AEROBICS',
  };

  public static readonly rockClimbing: ExternalActivityEntry = {
    externalName: 'ROCK_CLIMBING',
    translateKey: 'EXTERNAL_ACTIVITIES.ROCK_CLIMBING',
  };

  public static readonly speedWalking: ExternalActivityEntry = {
    externalName: 'SPEED_WALKING',
    translateKey: 'EXTERNAL_ACTIVITIES.SPEED_WALKING',
  };

  public static readonly paddleSports: ExternalActivityEntry = {
    externalName: 'Paddle_sports',
    translateKey: 'EXTERNAL_ACTIVITIES.PADDLE_SPORTS',
  };

  public static readonly golf: ExternalActivityEntry = {
    externalName: 'GOLF',
    translateKey: 'EXTERNAL_ACTIVITIES.GOLF',
  };

  public static readonly martialArts: ExternalActivityEntry = {
    externalName: 'Martial_arts',
    translateKey: 'EXTERNAL_ACTIVITIES.MARTIAL_ARTS',
  };

  public static readonly barre: ExternalActivityEntry = {
    externalName: 'BARRE',
    translateKey: 'EXTERNAL_ACTIVITIES.BARRE',
  };

  public static readonly fitnessEquipment: ExternalActivityEntry = {
    externalName: 'FITNESS_EQUIPMENT',
    translateKey: 'EXTERNAL_ACTIVITIES.FITNESS_EQUIPMENT',
  };

  public static readonly floorClimbing: ExternalActivityEntry = {
    externalName: 'FLOOR_CLIMBING',
    translateKey: 'EXTERNAL_ACTIVITIES.FLOOR_CLIMBING',
  };

  public static readonly runningTreadmill: ExternalActivityEntry = {
    externalName: 'RUNNING.TREADMILL',
    translateKey: 'EXTERNAL_ACTIVITIES.RUNNING_TREADMILL',
  };

  public static readonly tableTennis: ExternalActivityEntry = {
    externalName: 'TABLE_TENNIS',
    translateKey: 'EXTERNAL_ACTIVITIES.TABLE_TENNIS',
  };

  public static readonly backCountrySkiing: ExternalActivityEntry = {
    externalName: 'BACKCOUNTRY_SKIING',
    translateKey: 'EXTERNAL_ACTIVITIES.BACKCOUNTRY_SKIING',
  };

  public static readonly swimmingPool: ExternalActivityEntry = {
    externalName: 'SWIMMING.POOL',
    translateKey: 'EXTERNAL_ACTIVITIES.SWIMMING_POOL',
  };

  public static readonly volleyballBeach: ExternalActivityEntry = {
    externalName: 'VOLLEYBALL.BEACH',
    translateKey: 'EXTERNAL_ACTIVITIES.VOLLEYBALL_BEACH',
  };

  public static readonly mountainBiking: ExternalActivityEntry = {
    externalName: 'MOUNTAIN_BIKING',
    translateKey: 'EXTERNAL_ACTIVITIES.MOUNTAIN_BIKING',
  };

  public static readonly fencing: ExternalActivityEntry = {
    externalName: 'FENCING',
    translateKey: 'EXTERNAL_ACTIVITIES.FENCING',
  };

  public static readonly bikingMountain: ExternalActivityEntry = {
    externalName: 'BIKING.MOUNTAIN',
    translateKey: 'EXTERNAL_ACTIVITIES.BIKING_MOUNTAIN',
  };

  public static readonly cricket: ExternalActivityEntry = {
    externalName: 'Cricket',
    translateKey: 'EXTERNAL_ACTIVITIES.CRICKET',
  };

  public static readonly iceSkating: ExternalActivityEntry = {
    externalName: 'ICE_SKATING',
    translateKey: 'EXTERNAL_ACTIVITIES.ICE_SKATING',
  };

  public static readonly assistance: ExternalActivityEntry = {
    externalName: 'ASSISTANCE',
    translateKey: 'EXTERNAL_ACTIVITIES.ASSISTANCE',
  };

  public static readonly skiingCrossCountry: ExternalActivityEntry = {
    externalName: 'SKIING.CROSS_COUNTRY',
    translateKey: 'EXTERNAL_ACTIVITIES.SKIING_CROSS_COUNTRY',
  };

  public static readonly roadBiking: ExternalActivityEntry = {
    externalName: 'ROAD_BIKING',
    translateKey: 'EXTERNAL_ACTIVITIES.ROAD_BIKING',
  };

  public static readonly kickBoxing: ExternalActivityEntry = {
    externalName: 'KICKBOXING',
    translateKey: 'EXTERNAL_ACTIVITIES.KICKBOXING',
  };

  public static readonly virtualRun: ExternalActivityEntry = {
    externalName: 'VIRTUAL_RUN',
    translateKey: 'EXTERNAL_ACTIVITIES.VIRTUAL_RUN',
  };

  public static readonly stairs: ExternalActivityEntry = {
    externalName: 'Stairs',
    translateKey: 'EXTERNAL_ACTIVITIES.STAIRS',
  };

  public static readonly incidentDetected: ExternalActivityEntry = {
    externalName: 'INCIDENT_DETECTED',
    translateKey: 'EXTERNAL_ACTIVITIES.INCIDENT_DETECTED',
  };

  public static readonly transitionV2: ExternalActivityEntry = {
    externalName: 'TRANSITION_V2',
    translateKey: 'EXTERNAL_ACTIVITIES.TRANSITION_V2',
  };

  public static readonly skatingWs: ExternalActivityEntry = {
    externalName: 'SKATING_WS',
    translateKey: 'EXTERNAL_ACTIVITIES.SKATING_WS',
  };

  public static readonly jumpRope: ExternalActivityEntry = {
    externalName: 'Jump_rope',
    translateKey: 'EXTERNAL_ACTIVITIES.JUMP_ROPE',
  };

  public static readonly ergometer: ExternalActivityEntry = {
    externalName: 'ERGOMETER',
    translateKey: 'EXTERNAL_ACTIVITIES.ERGOMETER',
  };

  public static readonly waterPolo: ExternalActivityEntry = {
    externalName: 'Water_polo',
    translateKey: 'EXTERNAL_ACTIVITIES.WATER_POLO',
  };

  public static readonly openWaterSwimming: ExternalActivityEntry = {
    externalName: 'OPEN_WATER_SWIMMING',
    translateKey: 'EXTERNAL_ACTIVITIES.OPEN_WATER_SWIMMING',
  };

  public static readonly dancingSocial: ExternalActivityEntry = {
    externalName: 'DANCING.SOCIAL',
    translateKey: 'EXTERNAL_ACTIVITIES.DANCING_SOCIAL',
  };

  public static readonly mountaineering: ExternalActivityEntry = {
    externalName: 'MOUNTAINEERING',
    translateKey: 'EXTERNAL_ACTIVITIES.MOUNTAINEERING',
  };

  public static readonly inlineSkating: ExternalActivityEntry = {
    externalName: 'Inline Skating',
    translateKey: 'EXTERNAL_ACTIVITIES.INLINE_SKATING',
  };

  public static readonly boxing: ExternalActivityEntry = {
    externalName: 'BOXING',
    translateKey: 'EXTERNAL_ACTIVITIES.BOXING',
  };

  public static readonly multiSport: ExternalActivityEntry = {
    externalName: 'MULTI_SPORT',
    translateKey: 'EXTERNAL_ACTIVITIES.MULTI_SPORT',
  };

  public static readonly eBikeFitness: ExternalActivityEntry = {
    externalName: 'E_BIKE_FITNESS',
    translateKey: 'EXTERNAL_ACTIVITIES.E_BIKE_FITNESS',
  };

  public static readonly preparationAndRecovery: ExternalActivityEntry = {
    externalName: 'Preparation_and_recovery',
    translateKey: 'EXTERNAL_ACTIVITIES.PREPARATION_AND_RECOVERY',
  };

  public static readonly volleyball: ExternalActivityEntry = {
    externalName: 'VOLLEYBALL',
    translateKey: 'EXTERNAL_ACTIVITIES.VOLLEYBALL',
  };

  public static readonly snowShoeing: ExternalActivityEntry = {
    externalName: 'SNOWSHOEING',
    translateKey: 'EXTERNAL_ACTIVITIES.SNOWSHOEING',
  };

  public static readonly bikingRoad: ExternalActivityEntry = {
    externalName: 'BIKING.ROAD',
    translateKey: 'EXTERNAL_ACTIVITIES.BIKING_ROAD',
  };

  public static readonly stairClimbingMachine: ExternalActivityEntry = {
    externalName: 'STAIR_CLIMBING.MACHINE ',
    translateKey: 'EXTERNAL_ACTIVITIES.STAIR_CLIMBING_MACHINE',
  };

  public static readonly fitnessGaming: ExternalActivityEntry = {
    externalName: 'FITNESS_GAMING',
    translateKey: 'EXTERNAL_ACTIVITIES.FITNESS_GAMING',
  };

  public static readonly indoorClimbing: ExternalActivityEntry = {
    externalName: 'INDOOR_CLIMBING',
    translateKey: 'EXTERNAL_ACTIVITIES.INDOOR_CLIMBING',
  };

  public static readonly trailRunning: ExternalActivityEntry = {
    externalName: 'TRAIL_RUNNING',
    translateKey: 'EXTERNAL_ACTIVITIES.TRAIL_RUNNING',
  };

  public static readonly zumba: ExternalActivityEntry = {
    externalName: 'ZUMBA',
    translateKey: 'EXTERNAL_ACTIVITIES.ZUMBA',
  };
  public static readonly outdoorActivities: ExternalActivityEntry[] = [
    ExternalActivity.calisthenics,
    ExternalActivity.lapSwimming,
    ExternalActivity.football,
    ExternalActivity.footballSoccer,
    ExternalActivity.rowing,
    ExternalActivity.runningJogging,
    ExternalActivity.snowboarding,
    ExternalActivity.squash,
    ExternalActivity.tennis,
    ExternalActivity.biking,
    ExternalActivity.other,
    ExternalActivity.resortSkiingSnowBoardingWs,
    ExternalActivity.skiingDownhill,
    ExternalActivity.snowSports,
    ExternalActivity.crossCountrySkiingWs,
    ExternalActivity.skating,
    ExternalActivity.badminton,
    ExternalActivity.horsebackRiding,
    ExternalActivity.rockClimbing,
    ExternalActivity.speedWalking,
    ExternalActivity.paddleSports,
    ExternalActivity.golf,
    ExternalActivity.backCountrySkiing,
    ExternalActivity.volleyballBeach,
    ExternalActivity.mountainBiking,
    ExternalActivity.bikingMountain,
    ExternalActivity.cricket,
    ExternalActivity.iceSkating,
    ExternalActivity.skiingCrossCountry,
    ExternalActivity.roadBiking,
    ExternalActivity.openWaterSwimming,
    ExternalActivity.mountaineering,
    ExternalActivity.multiSport,
    ExternalActivity.eBikeFitness,
    ExternalActivity.snowShoeing,
    ExternalActivity.bikingRoad,
    ExternalActivity.fitnessGaming,
    ExternalActivity.trailRunning,
    ExternalActivity.steps,
  ];

  public static readonly indoorActivities: ExternalActivityEntry[] = [
    ExternalActivity.bikingStationary,
    ExternalActivity.breathWork,
    ExternalActivity.coolDown,
    ExternalActivity.coreTraining,
    ExternalActivity.elliptical,
    ExternalActivity.functionalStrength,
    ExternalActivity.gymnastics,
    ExternalActivity.indoorCardio,
    ExternalActivity.indoorCycling,
    ExternalActivity.intervalTraining,
    ExternalActivity.intervalTrainingHighIntensity,
    ExternalActivity.mixedMetabolicCardio,
    ExternalActivity.stairClimbing,
    ExternalActivity.strengthTraining,
    ExternalActivity.treadmillRunning,
    ExternalActivity.circuitTraining,
    ExternalActivity.guidedBreathing,
    ExternalActivity.flexibility,
    ExternalActivity.indoorRowing,
    ExternalActivity.weightLifting,
    ExternalActivity.aerobics,
    ExternalActivity.martialArts,
    ExternalActivity.barre,
    ExternalActivity.fitnessEquipment,
    ExternalActivity.floorClimbing,
    ExternalActivity.runningTreadmill,
    ExternalActivity.tableTennis,
    ExternalActivity.swimmingPool,
    ExternalActivity.fencing,
    ExternalActivity.assistance,
    ExternalActivity.kickBoxing,
    ExternalActivity.virtualRun,
    ExternalActivity.stairs,
    ExternalActivity.incidentDetected,
    ExternalActivity.transitionV2,
    ExternalActivity.skatingWs,
    ExternalActivity.jumpRope,
    ExternalActivity.ergometer,
    ExternalActivity.waterPolo,
    ExternalActivity.dancingSocial,
    ExternalActivity.inlineSkating,
    ExternalActivity.boxing,
    ExternalActivity.preparationAndRecovery,
    ExternalActivity.volleyball,
    ExternalActivity.stairClimbingMachine,
    ExternalActivity.indoorClimbing,
    ExternalActivity.zumba,
  ];

  public static readonly activities: ExternalActivityEntry[] = [
    ...ExternalActivity.indoorActivities,
    ...ExternalActivity.outdoorActivities,
  ];

  public static getTranslateKeyByName(activityName: string): string | undefined {
    return ExternalActivity.activities.find(a => a.externalName.toLowerCase() === activityName.toLowerCase())
      ?.translateKey;
  }

  public static isOutdoor(activityName: string): boolean {
    return !!ExternalActivity.outdoorActivities.find(a => a.externalName === activityName);
  }

  public static isIndoor(activityName: string): boolean {
    return !!ExternalActivity.indoorActivities.find(a => a.externalName === activityName);
  }
}
