import {
  AfterViewInit,
  Component,
  EnvironmentInjector,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
  OnInit
} from '@angular/core';
import { StatusBarPlugin, Style } from '@capacitor/status-bar';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '@fitup-monorepo/core/lib/environment';
import { filter, takeUntil } from 'rxjs/operators';
import { firstValueFrom, fromEvent, Subject, Subscription } from 'rxjs';
import * as moment from 'moment';
import { register } from 'swiper/element/bundle';
import { Store } from '@ngrx/store';
import { BrowserPlugin } from '@capacitor/browser';
import { App, AppPlugin, URLOpenListenerEvent } from '@capacitor/app';
import { APP, BROWSER, STATUS_BAR } from '@fitup-monorepo/core/lib/capacitor-injection-tokens';
import { AppScreenOrientationService } from '@fitup-monorepo/core/lib/services/app-screen-orientation/app-screen-orientation.service';
import { LoggingService } from '@fitup-monorepo/core/lib/services/logging/logging.service';
import { StateService } from '@fitup-monorepo/core/lib/services/state/state.service';
import { PurchaseService } from '@fitup-monorepo/core/lib/services/purchase/purchase.service';
import { PlatformService } from '@fitup-monorepo/core/lib/services/platform/platform.service';
import { CustomAppService } from '@fitup-monorepo/core/lib/services/custom-app/custom-app.service';
import { AuthServerProvider } from '@fitup-monorepo/core/lib/services/auth/auth-jwt.service';
import { profileApiActions } from '@fitup-monorepo/core/lib/state/profile/profile.actions';
import { AppVersionInterceptor } from '@fitup-monorepo/core/lib/interceptors/app-version.interceptor';
import { selectCustomer } from '@fitup-monorepo/core/lib/state/profile/profile.selector';
import { ActivityStorageService } from '@fitup-monorepo/activity/lib/services/activity-storage.service';
import { LocationService } from '@fitup-monorepo/activity/lib/services/location.service';
import { HeartRateService } from '@fitup-monorepo/activity/lib/services/heart-rate.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { Location } from '@angular/common';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  public closed$ = new Subject<void>();
  private backButtonSubscription: Subscription;

  constructor(
    @Inject(BROWSER) private readonly browser: BrowserPlugin,
    @Inject(APP) private readonly app: AppPlugin,
    @Inject(STATUS_BAR) private readonly statusBar: StatusBarPlugin,
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    public navController: NavController,
    private platform: Platform,
    public screenOrientation: AppScreenOrientationService,
    private router: Router,
    private ngZone: NgZone,
    public translateService: TranslateService,
    private activityStorageService: ActivityStorageService,
    private locationService: LocationService,
    private heartRateService: HeartRateService,
    public environmentInjector: EnvironmentInjector,
    private readonly loggingService: LoggingService,
    private readonly stateService: StateService,
    private readonly purchaseService: PurchaseService,
    private readonly platformService: PlatformService,
    private readonly customerAppService: CustomAppService,
    private readonly modalController: ModalController,
    private readonly authServerProvider: AuthServerProvider,
    private readonly activatedRoute: ActivatedRoute,
    private readonly location: Location,
    private store: Store
  ) {}

  public async ngAfterViewInit(): Promise<void> {
    await this.screenOrientation.init('AppComponent.afterViewInit');
  }

  @HostListener('window:resize')
  public async updateSafeArea(): Promise<void> {
    await this.screenOrientation.handleInsets('window.resize');
  }

  public async ngOnInit(): Promise<void> {
    await this.initializeApp();

    console.log('ngOnInit AppComponent');
    this.platform.pause.pipe(takeUntil(this.closed$)).subscribe(() => {
      console.log('App went into background', JSON.stringify(this.stateService.getState()));
    });

    this.platform.resume.pipe(takeUntil(this.closed$)).subscribe(() => {
      console.log('App resumed!', JSON.stringify(this.stateService.getState()));

      App.getLaunchUrl().then(data => {
        console.log('App.getLaunchUrl', JSON.stringify(data));
      });

      this.authServerProvider.updateJWTIfNecessary();
    });

    await this.authServerProvider.updateJWTIfNecessary();

    window.addEventListener('online', () => {
      console.log('App went back online');
      if (this.authServerProvider.isAuthenticated()) {
        this.activityStorageService.checkForUnsavedActivity();
      }
    });

    // hide tab bar on specific pages
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        takeUntil(this.closed$)
      )
      .subscribe((event: any) => {
        if (event.url.indexOf('tracking') !== -1 || event.url.indexOf('fullscreen') !== -1) {
          const tabBar = document.getElementById('my-tabs');
          if (tabBar !== null && tabBar.style.display !== 'none') {
            tabBar.style.display = 'none';
          }
        } else {
          const tabBar = document.getElementById('my-tabs');
          if (tabBar !== null && tabBar.style.display === 'none') {
            tabBar.style.display = 'inherit';
          }
        }
      });

    try {
      if (this.platform.is('capacitor')) {
        console.log('Hiding splash screen');
        await SplashScreen.hide({ fadeOutDuration: 100 });
      }
    } catch (e) {
      console.error('Error hiding splash screen', e);
    }
    console.log('ngOnInit AppComponent done');
  }

  private async initializeApp(): Promise<void> {
    console.log('initializeApp');
    await this.platform.ready();
    await this.platformService.initialize();

    console.log('initialize logging and set platform and version');
    try {
      const info = await this.app.getInfo();
      await this.loggingService.init(info.version);

      AppVersionInterceptor.setPlatformAndVersion(this.platform.platforms(), info.version);
    } catch (e) {
      await this.loggingService.init(environment.production ? 'web-prod' : 'web-dev');
      AppVersionInterceptor.setPlatformAndVersion(['browser'], environment.production ? 'prod' : 'dev');
    }

    await App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.ngZone.run(() => {
        console.log('appUrlOpen', JSON.stringify(event));
        this.handleOpenURL(event.url);
      });
    });

    console.log('initialize purchaseService service');
    this.purchaseService.init().catch(e => console.error('error initializing purchase service', e));

    if (this.platform.is('capacitor')) {
      console.log('Set status bar style');
      await this.statusBar.setStyle({ style: Style.Light });
    }

    console.log('Initiate translation');
    this.initTranslate();

    // set the style of the status bar to default
    if (this.platform.is('android')) {
      await this.statusBar.setOverlaysWebView({ overlay: true });
    }

    console.log('Adding back button subscription');
    // override the back button press
    const event = fromEvent(document, 'backbutton');

    this.backButtonSubscription = event.subscribe(async event => {
      const modal = await this.modalController.getTop();
      if (modal) {
        event.preventDefault();
        event.stopPropagation();
        await this.screenOrientation.unlock();
        await modal.dismiss();
      }
    });

    const launchUrl = await App.getLaunchUrl();
    const currentRoute = this.location.path(false);
    console.log('launchUrl', launchUrl);
    console.log('route', currentRoute);
    console.log('initializeApp done');

    if (this.authServerProvider.isAuthenticated()) {
      setTimeout(() => {
        this.activityStorageService.checkForUnsavedActivity();
        this.locationService.sendLogs().catch(e => console.error('Error sending location logs', e));
      }, 1000);

      console.log('User is authenticated, loading profile');
      this.store.dispatch(profileApiActions.loadProfile());
      if (currentRoute === '/') {
        console.log('navigating home...');
        await this.router.navigate(['/tabs/home']);
      }
    } else {
      if (
        currentRoute.startsWith('/partner/') ||
        currentRoute.startsWith('/reset/') ||
        currentRoute.startsWith('/company/')
      ) {
        console.log('not authenticated. Keeping route as is ', currentRoute);
      } else {
        console.log('not authenticated. navigating welcome page');
        await this.router.navigate(['/welcome']);
      }
    }
  }

  public async handleOpenURL(urlString: string): Promise<void> {
    console.log('handleOpenUrl', urlString);

    if (this.platform.is('ios')) {
      try {
        await this.browser.close();
      } catch (e) {
        // ignore
      }
    }

    const url = new URL(urlString);
    const queryParams = Object.fromEntries(url.searchParams.entries());
    let route: string;
    if (urlString.startsWith(`${environment.urlScheme}://`)) {
      const end = urlString.indexOf('?');
      route = urlString.slice(environment.urlScheme.length + 2, end > 0 ? end : urlString.length);
    } else {
      route = url.pathname;
    }

    if (urlString.startsWith(`${environment.urlScheme}://oauth2`) || route.startsWith('/oauth2')) {
      console.log('Navigating to oauth2', route, queryParams);
      await this.navController.navigateForward('/oauth2', { queryParams });
    } else {
      if (this.authServerProvider.isAuthenticated()) {
        this.store.dispatch(profileApiActions.loadProfile());

        if (await this.customerAppService.isCustomAppAndPromotionCodeMustBeEntered()) {
          console.log(
            'Not navigating to',
            route,
            queryParams,
            url.hash.slice(1),
            'because promotion code must be entered first'
          );
          return;
        }
      }

      if (this.stateService.getState().isTrackingActivity) {
        console.warn("Ignoring deeplink because we're tracking an activity", urlString);
        return;
      } else {
        console.log('Navigating to', route, queryParams, url.hash.slice(1));
        await this.navController.navigateForward(route, {
          queryParams,
          fragment: url.hash.slice(1)
        });
      }
    }
  }

  public initTranslate(): void {
    const enLang = 'en';
    // Set the default language for translation strings, and the current language.
    this.translate.setDefaultLang(enLang);

    const currentLanguage = this.localStorageService.retrieve('locale');
    const browserLang = this.getBrowserLang();
    if (!currentLanguage) {
      console.log(
        `User's browser language: ${this.translateService.getBrowserLang()}; setting language to ${browserLang}`
      );
      this.localStorageService.store('locale', browserLang);
    }
    this.translate.use(currentLanguage ?? browserLang);
    moment.locale(currentLanguage ?? browserLang);

    firstValueFrom(this.store.select(selectCustomer).pipe(filter(customer => !!customer))).then(customer => {
      console.log('setting language from customer', customer.language);
      this.translate.use(customer.language);
      moment.locale(customer.language);
      this.localStorageService.store('locale', customer.language);
    });
  }

  private getBrowserLang(): string {
    const lang = this.translateService.getBrowserLang();
    return lang === 'en' || lang === 'de' ? lang : 'en';
  }

  public async ngOnDestroy(): Promise<void> {
    console.log('ngOnDestroy AppComponent');
    this.closed$.next(); // <-- close subscription when component is destroyed
    this.locationService.setDesiredState('stopped');
    await this.heartRateService.disconnect();
    this.backButtonSubscription.unsubscribe();
  }
}
