import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HeaderComponent } from '@fitup-monorepo/components/lib/header/header.component';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import {
  ShareActivityComponent,
  ShareActivityConfig
} from '@fitup-monorepo/components/lib/share-activity/share-activity.component';
import { Milestone } from '../../models/milestones';

@Component({
  selector: 'lib-milestones-modal',
  templateUrl: './milestone-modal.component.html',
  styleUrls: ['./milestone-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [HeaderComponent, IonicModule, TranslateModule, RouterModule],
  standalone: true
})
export class MilestoneModalComponent {
  @Input()
  public milestone: Milestone;

  @Input()
  public shareActivityConfig: ShareActivityConfig;

  constructor(private readonly modalController: ModalController) {}

  protected async onCloseModal(): Promise<void> {
    await this.modalController.dismiss(null, null, 'milestone-modal');
  }

  protected async openShareActivityModal(): Promise<void> {
    await this.onCloseModal();
    await ShareActivityComponent.openModal(this.modalController, this.shareActivityConfig);
  }
}
