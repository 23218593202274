import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { formatDate } from '@angular/common';
import { Subscription } from 'rxjs';

@Pipe({
  name: 'localizedDate',
  pure: false,
  standalone: true
})
export class LocalizedDatePipe implements PipeTransform, OnDestroy {
  private value: string | undefined;
  private langChangeSubscription: Subscription | undefined;

  constructor(private readonly translateService: TranslateService) {}

  public transform(value: string | number | Date, pattern = 'mediumDate'): string {
    this.setCurrentValue(value, pattern);
    if (!this.langChangeSubscription) {
      this.langChangeSubscription = this.translateService.onLangChange.subscribe(() => {
        this.setCurrentValue(value, pattern);
      });
    }
    return this.value;
  }

  private setCurrentValue(value: string | number | Date, pattern = 'mediumDate'): void {
    this.value = formatDate(value, this.getLocalizedPattern(pattern), this.translateService.currentLang ?? 'en');
  }

  private getLocalizedPattern(pattern: string): string {
    const lang = this.translateService.currentLang ?? 'en';
    switch (pattern) {
      case 'shortWithoutYear':
        return lang === 'de' ? 'd. MMM' : 'MMM d';
      case 'shortWithYear':
        return lang === 'de' ? 'd. MMM yyyy' : 'MMM d, yyyy';
      case 'monthDayDate':
        return lang === 'de' ? 'd. MMMM' : 'MMMM d';
      case 'longDateTime':
        return lang === 'de' ? 'd. MMMM yyyy - HH:mm' : 'd MMMM, yyyy - HH:mm';
      case 'longDateTimeWithShortMonth':
        return lang === 'de' ? 'd. MMM yyyy - HH:mm' : 'd MMM, yyyy - HH:mm';
      case 'dayAndDate':
        return lang === 'de' ? 'EEEE, d. MMMM' : 'EEEE, d MMMM';
      default:
        return pattern;
    }
  }

  public ngOnDestroy(): void {
    this.langChangeSubscription?.unsubscribe();
  }
}
