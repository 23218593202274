import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Filter, FilterDefinition, FilterSet, FirestoreEntity, TokenResponse } from './firestore.model';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, firstValueFrom, Observable, of, retry } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ApiService } from '../api/api.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { environment } from '../../environment';
import { AuthServerProvider } from '../auth/auth-jwt.service';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  constructor(
    private readonly http: HttpClient,
    private readonly auth: AngularFireAuth,
    private readonly firestore: AngularFirestore,
    private readonly authServerProvider: AuthServerProvider
  ) {
    this.authServerProvider
      .observeToken()
      .pipe(
        switchMap(token => {
          if (!this.authenticated.value && !!token) {
            //we're not logged in, we got a token, so we log in
            console.log('logging into firestore');
            fromPromise(this.loginToFirestore());
            return of(true);
          } else if (this.authenticated.value && !token) {
            //we're logged in, but we don't have a token, so we log out
            console.log('logging out of firestore');
            return of(false);
          }
          return of(undefined); //don't do anything
        }),
        filter(v => v !== undefined)
      )
      .subscribe(v => this.authenticated.next(v));
  }

  private async loginToFirestore(): Promise<void> {
    const tokenResponse = await firstValueFrom(this.http.get<TokenResponse>(`${ApiService.API_URL}/firebase/token`));
    await this.auth.signInWithCustomToken(tokenResponse.token);
  }

  private authenticated = new BehaviorSubject(false);

  private withAuthentication<T>(fn: () => Observable<T>, defaultValue: T): Observable<T> {
    return this.authenticated.pipe(
      switchMap(authenticated => {
        if (authenticated) {
          return fn();
        } else {
          return of(defaultValue);
        }
      })
    );
  }

  public testAuth(resultValue: string, defaultValue: string): Observable<string> {
    return this.withAuthentication(() => of(resultValue), defaultValue);
  }

  private getFinalPath(path: String): string {
    return `${environment.firebase.prefix}${path}`;
  }

  public observeCollection<T extends FirestoreEntity>(path: string): Observable<T[]> {
    const finalPath = this.getFinalPath(path);
    console.log('Observing collection', finalPath);
    return this.withAuthentication(
      () =>
        this.firestore
          .collection<T>(finalPath)
          .snapshotChanges()
          .pipe(
            retry(10),
            map(c =>
              c.map(d => {
                const entity = d.payload.doc.data();
                entity.id = d.payload.doc.id;
                return entity;
              })
            ),
            catchError(err => {
              console.error('error observing collection', finalPath, JSON.stringify(err));
              return of([]);
            })
          ),
      []
    );
  }

  public observeDocument<T>(path: string): Observable<T> {
    const finalPath = this.getFinalPath(path);
    console.log('Observing document', finalPath);
    return this.withAuthentication(
      () =>
        this.firestore
          .doc<T>(finalPath)
          .snapshotChanges()
          .pipe(
            retry(10),
            map(d => d.payload.data()),
            catchError(err => {
              console.error('error observing document', finalPath, JSON.stringify(err));
              return of(undefined);
            }),
            filter(d => !!d)
          ),
      undefined
    );
  }

  public findAndObserveDocuments<T extends FirestoreEntity>(
    path: string,
    filters: FilterDefinition[]
  ): Observable<T[]> {
    const filterSet = new FilterSet(filters.map(f => new Filter(f)));
    const finalPath = this.getFinalPath(path);
    console.log('Finding and observing documents', finalPath, filters);
    return this.withAuthentication(
      () =>
        this.firestore
          .collection(finalPath)
          .get()
          .pipe(
            switchMap(c => fromPromise(filterSet.apply(c.query).get())),
            map(d => d.docs.map(e => e.data() as T)),
            catchError(err => {
              console.error('error finding and observing documents', finalPath, JSON.stringify(err));
              return of([]);
            })
          ),
      []
    );
  }
}
