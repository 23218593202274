import { firstValueFrom } from 'rxjs';
import { errorHandlingWithToast, isErrorResult } from './rxjs-util';
import { TeamSelectionService } from '../services/team-selection/team-selection.service';
import { ToastService } from '../services/toast/toast.service';
import { selectProfileFeature } from '../state/profile/profile.selector';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';

export async function hasSelectableTeam(
  teamSelectionService: TeamSelectionService,
  toastService: ToastService
): Promise<boolean> {
  const teams = await firstValueFrom(
    teamSelectionService.getSelectableTeams().pipe(errorHandlingWithToast(toastService))
  );

  return !isErrorResult(teams) && !!teams.length;
}

export async function showTrialPage(store: Store): Promise<boolean> {
  const profile = await firstValueFrom(store.select(selectProfileFeature).pipe(filter(c => !!c)));

  // show trial page only if user is not assigned to a partner and not has an active membership
  return profile.customer.partnerId === undefined && profile.currentMembership === undefined;
}

export async function isUsingZppPromotionCode(store: Store): Promise<boolean> {
  const profile = await firstValueFrom(store.select(selectProfileFeature));
  return profile.customer.promotionCode === 'VCZPP384';
}
