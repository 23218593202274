import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { FirestoreService } from '@fitup-monorepo/core/lib/services/firestore/firestore.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { addDays, daysBetween, isSameDate } from '@fitup-monorepo/core/lib/util/date.util';
import { ApiService } from '@fitup-monorepo/core/lib/services/api/api.service';
import { getMostRecentObject, sortByBoolean } from '@fitup-monorepo/core/lib/util/array-util';
import { customMealImage } from './custom-meal-image';
import {
  FbCustomerPlan,
  FbCustomMeal,
  FbNutritionMeal,
  FbPlanDay,
  FbPlanTemplate,
  FbRecipe
} from './model/firestore-plan';
import {
  CustomMealDetail,
  GroupedPlanResponse,
  InternalMealType,
  MealAlternative,
  MealDetail,
  OngoingPlan,
  PlanCategory,
  PlanDetail,
  PlanGender,
  PlanOngoingDetail,
  PlanOverview,
  PlanProgressDetail,
  PlanStatus,
  WeeklyPlanStatus
} from './model/plan';
import { MealAlternativeRequest } from './model/plan-request';
import { getUrlWithQuality } from '@fitup-monorepo/core/lib/util/cloudinary-util';
import { Gender } from '@fitup-monorepo/core/lib/model/customer';
import { CustomerSelectService } from '@fitup-monorepo/core/lib/services/customer-select/customer-select.service';
import { WeekDay } from '@fitup-monorepo/core/lib/model/weekday';
import { Permission, PermissionsService } from '@fitup-monorepo/core/lib/services/permissions/permissions.service';

@Injectable({
  providedIn: 'root'
})
export class PlanQueryService {
  private readonly customer$ = this.customerSelectService.selectCustomer$().pipe(
    tap(customer => {
      this.customerUUID = customer.uuid;
      this.gender = customer.gender;
    })
  );

  private customerUUID: string | undefined;
  private gender: Gender | undefined;

  constructor(
    public readonly httpClient: HttpClient,
    private readonly firestore: FirestoreService,
    private readonly customerSelectService: CustomerSelectService,
    private readonly permissionService: PermissionsService
  ) {}

  private observePlanCollection(): Observable<FbCustomerPlan[]> {
    return this.customer$.pipe(
      switchMap(customer =>
        this.firestore.observeCollection<FbCustomerPlan>(`customer/${customer.uuid}/nutrition-plan`)
      )
    );
  }

  private observeActivePlanDocument(): Observable<FbCustomerPlan | undefined> {
    return this.observePlanCollection().pipe(map(collection => collection.find(p => p.status === PlanStatus.active)));
  }

  private observeCurrentPlanDocument(): Observable<FbCustomerPlan | undefined> {
    return this.observePlanCollection().pipe(
      map(collection => {
        const activePlanId = collection.find(plan => plan.status === PlanStatus.active)?.id;
        if (activePlanId) {
          return activePlanId;
        }
        return getMostRecentObject(
          collection.filter(plan => plan.status === PlanStatus.completed),
          'updatedAt'
        )?.id;
      }),
      switchMap(planId =>
        planId
          ? this.firestore.observeDocument<FbCustomerPlan>(`customer/${this.customerUUID}/nutrition-plan/${planId}`)
          : of(undefined)
      )
    );
  }

  public getOngoingPlan(): Observable<OngoingPlan | undefined> {
    return this.observeCurrentPlanDocument().pipe(
      map(plan => (plan ? { id: plan.id, status: plan.status } : undefined))
    );
  }

  public getPlanOngoingDetail(date = new Date()): Observable<PlanOngoingDetail> {
    return this.observeCurrentPlanDocument().pipe(map(plan => this.mapToOngoingPlan(plan, date)));
  }

  private mapToOngoingPlan(plan: FbCustomerPlan, date: Date): PlanOngoingDetail | undefined {
    if (!plan) {
      return undefined;
    }
    const activeWeek = plan.weeks.find(week => week.status === WeeklyPlanStatus.active);
    const startDate = new Date(plan.startedAt);
    const endDate = addDays(startDate, plan.durationInWeeks * 7 - 1);
    const daysDiff = daysBetween(startDate, date);
    const requestedWeekNumber = daysDiff < 0 ? -1 : Math.floor(daysDiff / 7) + 1;
    const requestedWeek =
      requestedWeekNumber === -1
        ? undefined
        : requestedWeekNumber === activeWeek.numOfWeek
        ? activeWeek
        : plan.weeks.find(week => week.numOfWeek === requestedWeekNumber);
    const indexOfRequestedDay = requestedWeek?.days.findIndex(day => isSameDate(new Date(day.date), date));
    const requestedDay = requestedWeek?.days[indexOfRequestedDay];

    const previousWeek = plan.weeks.find(w => w.numOfWeek == activeWeek.numOfWeek - 1);
    const questionnaire =
      previousWeek && !previousWeek.endOfWeekQuestionnaire.completed ? previousWeek.endOfWeekQuestionnaire : undefined;
    return {
      id: plan.id,
      title: plan.titles,
      sourceTemplateId: plan.sourceTemplateId,
      target: plan.target,
      startDate,
      endDate,
      currentWeekPlan: {
        title: activeWeek.title,
        description: activeWeek.description,
        image: activeWeek.image,
        daysAmount: plan.weeks.reduce((prev, curr) => prev + curr.days.length, 0),
        completedDaysAmount: plan.weeks.reduce((prev, curr) => prev + curr.completedDaysAmount, 0),
        status: WeeklyPlanStatus.active,
        caloriesIntakeAmount: activeWeek.caloriesIntakeAmount,
        caloriesBurntAmount: activeWeek.caloriesBurnedAmount,
        numOfWeek: requestedWeek?.numOfWeek,
        endOfWeekQuestionnaire: activeWeek.endOfWeekQuestionnaire
      },
      dailyPlanDetails: {
        numOfDay: indexOfRequestedDay + 1,
        date: new Date(requestedDay.date),
        numOfWeek: requestedWeek?.numOfWeek,
        weekDay: requestedDay.day,
        caloriesIntakeAmount: requestedDay?.caloriesIntakeAmount ?? 0,
        caloriesBurntAmount: requestedDay?.caloriesBurntAmount ?? 0,
        caloriesLeftAmount: requestedDay?.caloriesLeftAmount ?? 0,
        carbsInGramsIntakeAmount: requestedDay?.carbsInGramsIntakeAmount ?? 0,
        proteinInGramsIntakeAmount: requestedDay?.proteinInGramsIntakeAmount ?? 0,
        fatInGramsIntakeAmount: requestedDay?.fatInGramsIntakeAmount ?? 0,
        meals:
          requestedDay?.meals.map(m => ({
            ...m,
            title: m.titles,
            mealType: m.mealType,
            numOfWeek: requestedWeekNumber,
            weekDay: requestedDay.day,
            date: new Date(requestedDay.date),
            planId: plan.id,
            trackedPortionAmount: m.numOfPortionsConsumed,
            image: m.image ? getUrlWithQuality(m.image, 50) : customMealImage,
            tags: m.type === InternalMealType.nutritionPlan ? m.tags : plan.tags
          })) ?? []
      },
      toCompleteQuestionnaire: questionnaire
    };
  }

  public getGroupedDiets(): Observable<GroupedPlanResponse> {
    return this.firestore.observeCollection<FbPlanTemplate>(`nutrition-plan-template`).pipe(
      switchMap(plans =>
        this.observeActivePlanDocument().pipe(
          map(currentPlan =>
            this.getFilteredPlansByPermission(plans).reduce<GroupedPlanResponse>(
              (planGroup, currPlan): GroupedPlanResponse => {
                const currentCategories = currPlan.category.reduce<GroupedPlanResponse>(
                  (categoryPlanGroup, currentCategory) => {
                    const categoryContent = planGroup[currentCategory] ?? {};
                    const categoryPlans = planGroup[currentCategory]?.plans ?? [];
                    return {
                      ...categoryPlanGroup,
                      [currentCategory]: {
                        ...categoryContent,
                        plans: sortByBoolean([...categoryPlans, this.toPlanOverview(currPlan, currentPlan)])
                      }
                    };
                  },
                  {}
                );
                return { ...planGroup, ...currentCategories };
              },
              {
                [PlanCategory.popular]: { color: '#fff', plans: [] },
                [PlanCategory.balanced]: { color: '#00b999', plans: [] },
                [PlanCategory.fasting]: { color: '#ff4a4a', plans: [] },
                [PlanCategory.highProtein]: { color: '#ffc700', plans: [] }
              }
            )
          )
        )
      )
    );
  }

  private getFilteredPlansByPermission(plans: FbPlanTemplate[]): FbPlanTemplate[] {
    return plans.filter(plan =>
      plan.permissions.some(permission => this.permissionService.hasPermission(permission as Permission))
    );
  }

  private toPlanOverview(plan: FbPlanTemplate, currentPlan: FbCustomerPlan | undefined): PlanOverview {
    return {
      id: plan.id,
      permissions: plan.permissions,
      title: plan.titles,
      durationInWeeks: plan.durationInWeeks,
      recipeAmount: plan.recipeAmount,
      image: plan.image,
      categories: plan.category,
      target: plan.targets[this.toPlanGender(this.gender)],
      isOngoing: plan.id === currentPlan?.sourceTemplateId,
      isFree: plan.isFree
    };
  }

  private toPlanGender(gender: Gender): PlanGender {
    if (gender === Gender.female) {
      return PlanGender.female;
    }
    if (gender === Gender.male) {
      return PlanGender.male;
    }
    return PlanGender.other;
  }

  public getPlanProgressDetails(): Observable<PlanProgressDetail> {
    return this.observeCurrentPlanDocument().pipe(map(plan => this.mapToPlanProgressDetails(plan)));
  }

  private mapToPlanProgressDetails(plan: FbCustomerPlan): PlanProgressDetail {
    if (!plan) {
      return undefined;
    }
    return {
      id: plan.id,
      permissions: plan.permissions,
      status: plan.status,
      title: plan.titles,
      completionResources: plan.completionResources,
      image: plan.image,
      durationInWeeks: plan.weeks.length,
      recipeAmount: plan.recipeAmount,
      categories: plan.category,
      isOngoing: true,
      target: plan.target,
      progressPercentage: plan.progressPercentage,
      trackedMealsAmount: plan.trackedMealsAmount,
      skippedMealsAmount: plan.skippedMealsAmount,
      caloriesBurntAmount: plan.caloriesBurntAmount,
      caloriesIntakeAmount: plan.caloriesIntakeAmount,
      weeksCompletedAmount: plan.weeksCompletedAmount,
      totalWeekAmount: plan.durationInWeeks,
      dailyCaloriesAmount: plan.target.calories,
      weeks: plan.weeks.map(w => ({ ...w, daysAmount: 7 })), // we assume that a week always has 7 days
      isFree: plan.isFree
    };
  }

  public getPlanDetail(planId: string): Observable<PlanDetail> {
    return this.firestore.observeDocument<FbPlanTemplate | undefined>(`nutrition-plan-template/${planId}`).pipe(
      filter(plan => !!plan),
      switchMap(plan =>
        this.observeActivePlanDocument().pipe(map(currentPlan => this.toPlanDetail(planId, plan, currentPlan)))
      )
    );
  }

  private toPlanDetail(planId: string, plan: FbPlanTemplate, currentPlan: FbCustomerPlan): PlanDetail {
    const id = plan.id ?? planId;
    return {
      ...plan,
      id,
      title: plan.titles,
      categories: plan.category,
      isOngoing: currentPlan?.sourceTemplateId === id,
      target: plan.targets[this.toPlanGender(this.gender)]
    };
  }

  public getMealAlternatives({
    planId,
    mealType,
    mealIndex,
    week,
    day,
    tags,
    recipeId
  }: MealAlternativeRequest): Observable<MealAlternative[]> {
    return this.httpClient
      .get<FbRecipe[]>(
        `${ApiService.API_URL}/nutrition/my-plan/${planId}/week/${week}/day/${day}/meal/${mealIndex}/${mealType}`,
        {
          params: {
            ...(recipeId ? { recipeId } : {}),
            tags: tags.join(',')
          }
        }
      )
      .pipe(map(recipes => recipes.map(r => ({ ...r, title: r.titles }))));
  }

  private observeRecipe(recipeId: string): Observable<FbRecipe> {
    return this.firestore.observeDocument<FbRecipe>(`recipe/${recipeId}`);
  }

  public getMealDetails(mealIndex: number, numOfWeek: number, weekDay: WeekDay): Observable<MealDetail> {
    return this.observeCurrentPlanDocument().pipe(
      map(plan => {
        const requestedDay = plan.weeks
          .find(week => week.numOfWeek === numOfWeek)
          ?.days.find(day => day.day === weekDay);
        const meal = requestedDay?.meals[mealIndex];
        return meal ? { ...meal, plan, requestedDay } : undefined;
      }),
      switchMap(meal => {
        if (meal?.type === InternalMealType.nutritionPlan) {
          return this.observeRecipe(meal.recipeId).pipe(
            map(recipe => this.toMealDetail(recipe, meal, numOfWeek, weekDay))
          );
        }
        if (meal?.type === InternalMealType.custom) {
          return of(this.customMealToDetail(meal, numOfWeek, weekDay));
        }
        return of(undefined);
      })
    );
  }

  private customMealToDetail(
    meal: FbCustomMeal & { plan: FbCustomerPlan; requestedDay: FbPlanDay },
    numOfWeek: number,
    weekDay: WeekDay
  ): CustomMealDetail {
    return {
      planId: meal.plan.id,
      date: new Date(meal.requestedDay.date),
      numOfWeek,
      weekDay,
      type: meal.type,
      status: meal.status,
      title: meal.titles,
      image: meal.image ?? customMealImage,
      proteinInGramsAmount: meal.proteinInGramsAmount,
      mealType: meal.mealType,
      caloriesAmount: meal.caloriesAmount,
      carbsInGramsAmount: meal.carbsInGramsAmount,
      fatInGramsAmount: meal.fatInGramsAmount,
      tags: meal.plan.tags,
      portionUnitName: meal.portionUnitName,
      portionAmount: meal.portionAmount
    };
  }

  private toMealDetail(
    recipe: FbRecipe,
    meal: FbNutritionMeal & { plan: FbCustomerPlan; requestedDay: FbPlanDay },
    numOfWeek: number,
    weekDay: WeekDay
  ): MealDetail {
    return {
      planId: meal.plan.id,
      date: new Date(meal.requestedDay.date),
      numOfWeek,
      weekDay,
      type: meal.type,
      recipeId: meal.recipeId,
      status: meal.status,
      title: recipe.titles,
      image: getUrlWithQuality(recipe.image, 50),
      preparationTimeMinutes: recipe.preparationTimeMinutes,
      portionAmount: recipe.portionAmount,
      proteinInGramsAmount: recipe.proteinInGramsAmount,
      mealType: meal.mealType,
      caloriesAmount: recipe.caloriesAmount,
      carbsInGramsAmount: recipe.fiberInGramsAmount,
      fatInGramsAmount: recipe.fatInGramsAmount,
      ingredients: recipe.ingredients.map(i => ({ ...i, name: i.names })),
      instructions: recipe.preparationInstructions,
      tags: recipe.tags
    };
  }
}
